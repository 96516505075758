import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import PublicImages from "../../../constants/public/images";
import { getRequest, uploadURL } from "../../../helper/api";
import Loader from "../../../components/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import ImageCard from "../../../components/public/Pages/ImageCard";
import { getItemFromLocalStorage } from "../../../helper/helper";
export default function Bookmarks({ currentlyActive, actualComponent }) {
  const [pending, setPending] = useState(true);
  const [allData, setAllData] = useState([]);
  const [allDataLength, setAllDataLength] = useState(0);
  const [postError, setPostsError] = useState("");
  const [postEnded, setPostEnded] = useState(true);
  const [posts, setPosts] = useState([]);
  const [postsPage, setPostsPage] = useState(1);

  const loadBookmarkedPosts = async (page = 1) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/userPreferences/get-bookmarks`,
        token,
        {
          page: page,
          limit:3
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        setPostsError("");
        setPosts(result?.data);
        if (page === 1) {
          setAllData(result?.data.posts);
          // Inside the function where you load more data
          setAllDataLength(result?.data.posts);
        } else {
          setAllData((prevData) => [...prevData, ...result?.data.posts]);
          // Inside the function where you load more data
          setAllDataLength(allData.length);
        }
        if (result?.data.currentPage === result?.data.totalPages) {
          setPostEnded(false);
        }
        setPostsPage((prevPage) => prevPage + 1);
      } else if (error?.response?.status === 400) {
        setPostsError(error?.response?.data?.message);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setPending(false);
    }
  };

  useEffect(() => {
    setPending(true);
    setAllData([]);
    setAllDataLength(0);
    setPostsError("");
    setPostEnded(true);
    setPosts([]);
    setPostsPage(1);
    if (currentlyActive === actualComponent) {
      loadBookmarkedPosts(1);
    }
  }, [currentlyActive, actualComponent]);

  return (
    <section className="mt-5 bg-white py-3">
      <Container>
        {pending ? (
          <Loader loading={true} />
        ) : (
          <div>
            {postError ? (
              postError
            ) : (
              <InfiniteScroll
                dataLength={allDataLength}
                next={() => {
                  loadBookmarkedPosts(postsPage);
                }}
                inverse={false}
                hasMore={postEnded}
                loader={<Loader loading={true} />}
                scrollThreshold={0.9}
                endMessage={
                  <p style={{ justifyContent: "center", textAlign: "center" }}>
                    <b>Yay! You've seen it all!</b>
                  </p>
                }
              >
                <Row className="m-0">
                  {allData.map((item, index) => (
                    <ImageCard
                      key={index}
                      image={uploadURL + item?.featuredImage}
                      authorImage={
                        item.userId?.profileImage
                          ? uploadURL + item.userId.profileImage
                          : ""
                      }
                      subCatName={`${item.subCategoryId?.name}`}
                      productSlug={`${item?.productId?.slug}`}
                      catSlug={`${item?.categoryId?.slug}`}
                      subCatSlug={`${item.subCategoryId?.slug}`}
                      authorName={`${item.userId.firstName} ${item.userId.lastName}`}
                      views={item.viewCount}
                      comments={item.comments}
                      likes={item.likeCount}
                      title={item.title}
                      date={item.createdAt}
                      username={item.userId.username}
                      link={`/${item.productId?.slug}/${item.categoryId?.slug}/${item.slug}`}
                      showBookmarkBtn={false}
                      
                    />
                  ))}
                </Row>
              </InfiniteScroll>
            )}
          </div>
        )}
      </Container>
    </section>
  );
}
