import {
  faHeart,
  faBookmark as notBookmarked,
} from "@fortawesome/free-regular-svg-icons";
import {
  faCircle,
  faBookmark as Bookmarked,
  faHeart as faHeartFilled,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Image,
  Row,
  Nav,
  Tab,
} from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Loader from "../../components/Loader";
import AdminImages from "../../constants/admin/images";
import PublicImages from "../../constants/public/images";
import { getRequest, postRequestForm, uploadURL } from "../../helper/api";
import { getItemFromLocalStorage } from "../../helper/helper";
import Layout from "../../layouts/public/Layout";
import * as _ from "underscore";
import { Tooltip } from "react-tooltip";

function Home() {
  const [sliderLoading, setSliderLoading] = useState(true);
  const [subSliderLoading, setSubSliderLoading] = useState(true);
  const [popularPostLoading, setPopularPostLoading] = useState(true);
  // const [productLoading, setProductLoading] = useState(true);
  const [topCreatorsLoading, setTopCreatorsLoading] = useState(true);
  const [slider, setSlider] = useState([]);
  const [subSlider, setSubSlider] = useState([]);
  const [posts, setPosts] = useState([]);
  // const [products, setProducts] = useState([]);
  const [users, setUsers] = useState([]);
  const [languagesettings, setLanguageSettings] = useState(null);
  const [isBookmark, setIsBookmark] = useState({});
  const [isLiked, setIsLiked] = useState({});
  const [isLiked_2, setIsLiked_2] = useState({});
  // const [trendingHashtags, setTrendingHashtags] = useState([]);
  const [trendingPosts, setTrendingPosts] = useState([]);
  const [challengesLoading, setChallengesLoading] = useState(true);
  const [challenges, setChallenges] = useState([]);
  // const [activeTab, setActiveTab] = useState("");
  const getAllSlider = async () => {
    try {
      setSliderLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(
        `/api/secure/slider/get-all-slider-active`,
        token
      );
      setSlider(response?.result?.data?.sliders);
    } catch (error) {
      console.log("Get All Slider Error", error);
    } finally {
      setSliderLoading(false);
    }
  };
  const getAllSubSlider = async () => {
    try {
      setSubSliderLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(
        `/api/secure/subSlider/get-all-sub-slider-active`,
        token
      );
      setSubSlider(response?.result?.data?.sliders);
    } catch (error) {
      console.log("Get All Slider Error", error);
    } finally {
      setSubSliderLoading(false);
    }
  };
  // const getTrendingHashtags = async () => {
  //   try {
  //     setSubSliderLoading(true);
  //     const token = getItemFromLocalStorage("TOKEN");
  //     const { result } = await getRequest(
  //       `/api/secure/post/get-trending-hashtags`,
  //       token
  //     );
  //     if (result?.status === 200 || result?.status === 201) {
  //       const { topHashtags } = result?.data;
  //       setActiveTab(topHashtags[0]?.hashtag);
  //       fetchTrendingPosts(topHashtags[0]?.hashtag);
  //       setTrendingHashtags(topHashtags);
  //     }
  //   } catch (error) {
  //     console.log("Get All Slider Error", error);
  //   } finally {
  //     setSubSliderLoading(false);
  //   }
  // };
  const fetchTrendingPosts = async () => {
    try {
      setSubSliderLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const { result } = await getRequest(
        `/api/secure/post/latest-posts`,
        // `/api/secure/post/get-trending-post-by-hashtags`,
        token,
        {
          recent: false,
          popular: true,
          page: 1,
          limit: 15,
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        const { latestPosts } = result?.data;
        setTrendingPosts(latestPosts);
      }
    } catch (error) {
      console.log("Get All Slider Error", error);
    } finally {
      setSubSliderLoading(false);
    }
  };
  const getAllPopularPosts = async () => {
    try {
      setPopularPostLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(
        `/api/secure/post/latest-posts`,
        token,
        {
          recent: true,
          popular: false,
          page: 1,
          limit: 15,
        }
      );
      setPosts(response?.result?.data?.latestPosts);
    } catch (error) {
      console.log("Get All Popular Posts Error", error);
    } finally {
      setPopularPostLoading(false);
    }
  };
  // const getAllProducts = async () => {
  //   try {
  //     setProductLoading(true);
  //     const token = getItemFromLocalStorage("TOKEN");
  //     const response = await getRequest(
  //       `/api/secure/product/get-all-products`,
  //       token,
  //       {
  //         status: "active",
  //       }
  //     );
  //     setProducts(response?.result?.data?.products);
  //   } catch (error) {
  //     console.log("Get All Products Error", error);
  //   } finally {
  //     setProductLoading(false);
  //   }
  // };
  const getAllTopContributors = async () => {
    try {
      setTopCreatorsLoading(true);
      const response = await getRequest(
        `/api/secure/user/get-all-contributors`,
        "",
        {
          page: 1,
          limit: 25,
        }
      );
      setUsers(response?.result?.data?.users);
    } catch (error) {
      console.log("Get All Top Contibutors Error", error);
    } finally {
      setTopCreatorsLoading(false);
    }
  };
  const handleBookmarkPost = async (postId) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      console.log("token", token);
      const { result, error } = await postRequestForm(
        `/api/secure/userPreferences/bookmark-post`,
        token,
        {
          postIdToToggle: postId,
        }
      );

      if (result?.status === 200 || result?.status === 201) {
        setIsBookmark((prev) => {
          let isBookmarked = false;
          const newState = { ...prev };
          if (newState[postId]) {
            // If post is already liked, remove it
            delete newState[postId];
            isBookmarked = false;
          } else {
            // If post is not liked, add it
            newState[postId] = true;
            isBookmarked = true;
          }
          if (isBookmarked) {
            toast.success(
              `Post has been ${
                isBookmarked ? "Bookmarked!" : "Un-Bookmarked!"
              }!`,
              {
                position: "top-center",
                theme: "colored",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
              }
            );
          } else {
            toast.error(
              `Post has been ${
                isBookmarked ? "Bookmarked!" : "Un-Bookmarked!"
              }!`,
              {
                position: "top-center",
                theme: "colored",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
              }
            );
          }
          return newState;
        });
      } else if (error?.response?.status === 400) {
        console.log(error?.response?.data?.message);
      }

      console.log(result);
    } catch (error) {
      console.log(error);
    }
  };
  const handleLikePost = async (postId, isTrending = false) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      console.log("token", token);
      const { result, error } = await postRequestForm(
        `/api/secure/userPreferences/like-post`,
        token,
        {
          postId: postId,
        }
      );

      if (result?.status === 200 || result?.status === 201) {
        if (isTrending) {
          setIsLiked_2((prev) => {
            const newState = { ...prev };
            let isLiked = false;

            if (newState[postId]) {
              // If post is already liked, remove it
              delete newState[postId];
              isLiked = false;
            } else {
              // If post is not liked, add it
              newState[postId] = true;
              isLiked = true;
            }

            // Update the like count in the corresponding post data
            setTrendingPosts((prevData) =>
              prevData.map((item) =>
                item._id === postId
                  ? {
                      ...item,
                      likeCount: isLiked
                        ? item.likeCount + 1
                        : item.likeCount - 1,
                    }
                  : item
              )
            );

            if (isLiked) {
              toast.success(`Post has been ${isLiked ? "Liked" : "Unliked"}!`, {
                position: "top-center",
                theme: "colored",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
              });
            } else {
              toast.error(`Post has been ${isLiked ? "Liked" : "Unliked"}!`, {
                position: "top-center",
                theme: "colored",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
              });
            }

            return newState;
          });
        } else {
          setIsLiked((prev) => {
            const newState = { ...prev };
            let isLiked = false;

            if (newState[postId]) {
              // If post is already liked, remove it
              delete newState[postId];
              isLiked = false;
            } else {
              // If post is not liked, add it
              newState[postId] = true;
              isLiked = true;
            }

            // Update the like count in the corresponding post data
            setPosts((prevData) =>
              prevData.map((item) =>
                item._id === postId
                  ? {
                      ...item,
                      likeCount: isLiked
                        ? item.likeCount + 1
                        : item.likeCount - 1,
                    }
                  : item
              )
            );

            if (isLiked) {
              toast.success(`Post has been ${isLiked ? "Liked" : "Unliked"}!`, {
                position: "top-center",
                theme: "colored",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
              });
            } else {
              toast.error(`Post has been ${isLiked ? "Liked" : "Unliked"}!`, {
                position: "top-center",
                theme: "colored",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
              });
            }
            return newState;
          });
        }
      } else if (error?.response?.status === 400) {
        console.log(error?.response?.data?.message);
      }

      console.log(result);
    } catch (error) {
      console.log(error);
    }
  };
  const getUserLikedAndBookmarkedPosts = useMemo(() => {
    return async () => {
      try {
        const storedUser = JSON.parse(getItemFromLocalStorage("USER"));
        if (storedUser && storedUser?.username) {
          const { result, error } = await getRequest(
            `/api/secure/user/profile/${storedUser?.username}`
          );
          if (result?.status === 200 || result?.status === 201) {
            const { userPrefrences } = result?.data;
            const { likedPost, bookmarks } = userPrefrences;
            setIsLiked((prev) => ({
              ...prev,
              ...likedPost.reduce(
                (acc, postId) => ({ ...acc, [postId]: true }),
                {}
              ),
            }));
            setIsLiked_2((prev) => ({
              ...prev,
              ...likedPost.reduce(
                (acc, postId) => ({ ...acc, [postId]: true }),
                {}
              ),
            }));
            setIsBookmark((prev) => ({
              ...prev,
              ...bookmarks.reduce(
                (acc, postId) => ({ ...acc, [postId]: true }),
                {}
              ),
            }));
          }
        } else {
          setIsLiked({});
          setIsBookmark({});
        }
      } catch (error) {
        console.error("Error while fetching user:", error);
        setIsLiked({});
        setIsBookmark({});
      }
    };
  }, []);
  useEffect(() => {
    getUserLikedAndBookmarkedPosts();
  }, [getUserLikedAndBookmarkedPosts]);

  const imageStyle = {
    height: "270px",
    width: "200px",
    objectFit: "contain",
  };

  const smallScreenStyle = {
    height: "270px",
    width: "100%",
  };
  const loadChallenges = async () => {
    try {
      setChallengesLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const res = await getRequest(
        `/api/secure/challenge/get-challenges-by-product-types`,
        "",
        {
          productSlug: [
            "blog",
            "short-stories",
            "image-corner",
            "videos",
            "audio-station",
          ],
        }
      );
      if (
        res.result.status === 200 ||
        res.result.status === 201 ||
        res.result.status === 204 ||
        res.result.status === 304
      ) {
        setChallenges(res.result.data.challenges);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setChallengesLoading(false);
    }
  };
  useEffect(() => {
    getAllSlider();
    getAllSubSlider();
    fetchTrendingPosts();
    getAllPopularPosts();
    loadChallenges();
    getAllTopContributors();
  }, []);
  const getValueByKey = (options, key) => {
    const option = options?.find((option) => option.key === key);
    return option?.value;
  };

  const HomePage = ({ languagesettings }) => {
    return (
      <>
        <section className="banner">
          <Container className="text-center" 
                style={{
                  position:"relative",
                  padding:"0 50px"
                }}>
            {sliderLoading ? (
              <Loader loading={sliderLoading} />
            ) : (
              <Swiper
                navigation={true}
                spaceBetween={0}
                loop={true}
                autoplay={{ delay: 3000, disableOnInteraction: false }}
                modules={[Autoplay,Navigation]}
                style={{
                  position:"static"
                }}
              >
                {slider?.length > 0 &&
                  slider.map(({ featuredImage, link, name }, index) => (
                    <SwiperSlide key={index} className="item">
                      <Link to={link !== "" ? link : "javascript:void(0);"}>
                        <Image
                          className="w-100"
                          fluid
                          src={uploadURL + featuredImage}
                          alt={name}
                        />
                      </Link>
                    </SwiperSlide>
                  ))}
              </Swiper>
            )}
          </Container>
        </section>
        {subSlider?.length > 0 && (
          <section className="banner py-2">
            <Container className="text-center">
              {subSliderLoading ? (
                <Loader loading={subSliderLoading} />
              ) : (
                <Swiper
                  navigation={true}
                  spaceBetween={0}
                  loop={true}
                  autoplay={{ delay: 3000, disableOnInteraction: false }}
                  modules={[Autoplay]}
                >
                  {subSlider.map(({ featuredImage, name, link }, index) => (
                    <SwiperSlide key={index} className="item">
                      <Link to={link !== "" ? link : "javascript:void(0);"}>
                        <Image
                          fluid
                          className="w-100"
                          src={uploadURL + featuredImage}
                          alt={name}
                        />
                      </Link>
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}
            </Container>
          </section>
        )}
        <section className="trending_sec  py-2 py-lg-3">
          <Container className="px-1">
            <h1 className="m-0 mb-lg-2">
              <Link to="/trending-posts">
                <img
                  src={PublicImages.TrendingPost}
                  className="img-fluid"
                  alt=""
                />
                TRENDING
              </Link>
            </h1>

            <section className="popular_sec bg-white p-0 p-lg-2">
              <Swiper
                // navigation={false}
                spaceBetween={20}
                loop={true}
                autoHeight={true}
                autoplay={{ delay: 5000, disableOnInteraction: false }}
                modules={[Autoplay, Pagination]}
                pagination={true}
                // pagination={{
                //   el: ".swiper-pagination",
                //   clickable: true,
                //   type: "custom",
                //   renderCustom: function (swiper, current, total) {
                //     let paginationHTML = "";
                //     const visiblePages = 3; // Number of visible pages before and after the current page

                //     for (let i = 1; i <= total; i++) {
                //       if (i === current) {
                //         // Highlight the current page
                //         paginationHTML += `<span class="swiper-pagination-bullet swiper-pagination-bullet-active">${i}</span>`;
                //       } else if (
                //         i >= current - visiblePages &&
                //         i <= current + visiblePages
                //       ) {
                //         // Display pages around the current page
                //         paginationHTML += `<span class="swiper-pagination-bullet">${i}</span>`;
                //       } else if (i === 1 || i === total) {
                //         // Always display the first and last page
                //         paginationHTML += `<span class="swiper-pagination-bullet">${i}</span>`;
                //       } else if (
                //         i === current - visiblePages - 1 ||
                //         i === current + visiblePages + 1
                //       ) {
                //         // Add ellipses between far away pages
                //         paginationHTML += `<span class="swiper-pagination-ellipsis">...</span>`;
                //       }
                //     }

                //     return paginationHTML;
                //   },
                // }}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                  640: {
                    slidesPerView: 2,
                  },
                  1024: {
                    slidesPerView: 4,
                  },
                }}
              >
                {trendingPosts?.map((item, index) => (
                  <SwiperSlide key={index} className="item">
                    <Card className="w-100 m-0">
                      {item.isStarmarked && (
                        <Link
                          to={`/${item?.productId?.slug}/challenges/${item.challengeId?.slug}`}
                          className="btn-default-2 addToBookmarkButton"
                          style={{
                            right: item.isWinner ? 105 : 57,
                          }}
                        >
                          <Image
                            src={AdminImages.StarmarkGold}
                            height={30}
                            width={30}
                            className="img-fluid"
                          />
                        </Link>
                      )}
                      {item.isWinner && (
                        <Link
                          to={`/${item?.productId?.slug}/challenges/${item.challengeId?.slug}`}
                          className="btn-default-2 addToBookmarkButton"
                          style={{
                            right: 57,
                          }}
                        >
                          <Image
                            src={PublicImages.Trophy}
                            height={30}
                            width={30}
                            className="img-fluid"
                          />
                        </Link>
                      )}
                      <Button
                        className="btn-default-2 addToBookmarkButton"
                        onClick={() => handleBookmarkPost(item._id)}
                      >
                        {isBookmark[item._id] ? (
                          <FontAwesomeIcon icon={Bookmarked} />
                        ) : (
                          <FontAwesomeIcon icon={notBookmarked} />
                        )}
                      </Button>
                      <Link
                        to={`/${item?.productId?.slug}/${item?.categoryId?.slug}/${item.slug}`}
                        className="d-block w-100"
                      >
                        <LazyLoadImage
                          src={uploadURL + item?.featuredImage}
                          alt={item.title}
                          effect="blur"
                          className="card-img card-img-top w-100"
                          delayTime={5000}
                          wrapperClassName="d-block w-100"
                        />
                      </Link>
                      <Card.Body className="text-center pb-0 pb-lg-auto">
                        <div className="post_info d-flex align-items-center justify-content-center ">
                          <span className="post_cat">
                            <Link
                              to={`/${item?.productId?.slug}/${item?.categoryId?.slug}?subcategory=${item.subCategoryId?.slug}`}
                            >
                              {item.subCategoryId?.name}
                            </Link>
                          </span>
                          <span className="post_created_on">
                            {moment(item.createdAt).format("D-M-Y")}
                          </span>
                        </div>
                        <Card.Title className="post_title text-center">
                          <Link
                            to={`/${item?.productId?.slug}/${item?.categoryId?.slug}/${item.slug}`}
                          >
                            {item.title}
                          </Link>
                        </Card.Title>
                        <div className="post_author_info_cover  flex-column-reverse flex-lg-row justify-content-center">
                          <div className="post_author_info d-flex gap-2 align-items-center justify-content-center mb-2">
                            {item.userId?.profileImage ? (
                              <Link to={`/profile/${item.userId.username}`}>
                                <LazyLoadImage
                                  src={uploadURL + item.userId.profileImage}
                                  alt={item.userId.username}
                                  effect="blur"
                                  className="card-img img-fluid author_img"
                                  delayTime={5000}
                                  wrapperClassName="d-block"
                                  width="32px"
                                  height="32px"
                                />
                              </Link>
                            ) : (
                              <Link to={`/profile/${item.userId.username}`}>
                                <svg
                                  width="32px"
                                  height="32px"
                                  xmlns="http://www.w3.org/2000/svg"
                                  version="1.1"
                                  id="Layer_1"
                                  x="0px"
                                  y="0px"
                                  viewBox="0 0 224.2 319.9"
                                >
                                  <path
                                    class="st0"
                                    fill="#E5097D"
                                    d="M48.1,107.5c-4.9-1.5-7.2-6.3-5-10c0.6-1,2.8-1.5,4.2-1.5c14.8-0.1,29.7-0.1,44.5-0.1  c15.7,0,22.3-5.1,26.5-20.1c3-10.7,5.9-21.5,9.4-32.1c0.8-2.4,3.3-5.4,5.6-5.9c3.8-0.9,5.2,2.8,6.1,5.9c3.6,12.5,6.9,25,10.6,37.5  c3.3,11.2,8.2,14.7,20,14.8c1.7,0,3.3,0,5,0c6.3,0.1,8.3,2.5,6.3,8.5c-0.5,1.4-2.8,2.2-5.1,4c0,6.4-0.1,13.8,0,21.3  c0.4,24.1-9.1,43.2-29,57c-1.6,1.1-2.7,3.7-2.8,5.7c-0.3,7.1,0,14.3-0.2,21.5c-0.1,3.7,1,4.9,4.8,4.8c11.8-0.2,23.7-0.1,35.5-0.1  c24,0.1,39.7,15.8,39.8,39.8c0.1,17.5,0,35,0,52.5c0,7.5-1.5,9-9.1,9c-68.6,0-137.3,0-205.9,0c-7.8,0-9.1-1.2-9.1-8.9  c0-17.7-0.1-35.3,0-53c0.2-20.6,13-36.6,31.6-38.8c8.2-1,16.6-0.5,25-0.5c6.5-0.1,13-0.1,19.5,0c2.8,0.1,3.9-1,3.8-3.8  c-0.1-7.8,0.1-15.7-0.2-23.5c-0.1-1.6-1.4-3.8-2.8-4.7c-20.2-14-29.4-33.3-28.9-57.6C48.2,122,48.1,114.8,48.1,107.5L48.1,107.5z   M212.6,308.9c0.3-0.7,0.6-1.2,0.6-1.6c0-18.2,0.4-36.3-0.2-54.5c-0.4-11.8-10.7-22.2-22.7-22.9c-15.6-0.8-31.3-0.6-46.9-0.5  c-1.7,0-3.7,1.4-5,2.6c-6.9,6.7-13.7,13.6-20.5,20.5c-4.6,4.5-6.8,4.5-11.3,0.1c-6.9-6.8-13.6-13.7-20.6-20.4  c-1.5-1.5-3.9-2.8-5.9-2.8c-13.8-0.2-27.7-0.4-41.5,0C21,229.9,11,240.6,10.9,258.1c-0.1,15.2,0,30.3,0,45.5c0,1.8,0.2,3.5,0.3,5.3  L212.6,308.9L212.6,308.9z M134,64.7l-1.3,0.2c-1.9,6.5-3.6,13.1-5.7,19.5c-3.9,12.4-14.6,21.1-27.7,21.7c-11.5,0.6-23,0.5-34.4,0.2  c-4.7-0.1-6.7,1.1-6.3,6.1c0.4,6,0.2,12,0.2,18c-0.2,30.8,20.3,53.3,50.9,55.7c22.7,1.8,46-14.4,52.8-36.2  c4.2-13.4,2.4-27.1,2.9-40.6c0-1-1.4-2.9-2.4-3.1c-14-2.1-20.9-11.1-23.9-24C137.6,76.3,135.7,70.6,134,64.7L134,64.7z M112.1,243.6  c7.1-7.3,13.4-13.7,19.6-20.2c0.7-0.7,1.4-1.7,1.4-2.5c0.1-8.9,0.1-17.8,0.1-27.3c-14.4,4.8-28.1,4.5-42.3,0.1c0,9.3,0,18,0.1,26.6  c0,0.9,0.4,2,1.1,2.7C98.4,229.5,104.9,236.2,112.1,243.6z"
                                  />
                                  <path
                                    class="st0"
                                    fill="#E5097D"
                                    d="M192,212.6c0-10.7,0-21.2,0-31.7c0-29.5,0-59,0-88.5c0.1-41.8-28.1-75.3-69.5-81C77.4,5.3,38.8,37,33.5,77.9  c-2.4,18.7-1.3,37.9-1.4,56.9c-0.2,24.3-0.1,48.6,0.1,73c0,4.3-1,5.7-5.5,5.8c-4.7,0-5.4-1.9-5.4-6c0.1-38.3,0-76.6,0.1-114.9  c0.1-41,24.7-75.8,62.2-88C134.8-12,188.7,18,200.2,70.6c3,13.6,2.4,28.1,2.5,42.2c0.3,30.6,0.1,61.3,0,92  C202.8,214.2,202.5,214.4,192,212.6L192,212.6z"
                                  />
                                  <path
                                    class="st0"
                                    fill="#E5097D"
                                    d="M131.8,155.1c-0.5,11.2-9.2,19.6-19.7,19.5c-10.7,0-19-8.1-19.8-19.4c4.1-0.9,7.6-1.9,9.3,4.1  c1.3,4.6,5.6,6.9,10.5,6.9s9.3-2.1,10.5-6.8C124.1,153.7,127.5,154.4,131.8,155.1L131.8,155.1z"
                                  />
                                  <path
                                    class="st0"
                                    fill="#E5097D"
                                    d="M85.5,118.7c5,0,9.2,4.2,9.1,9.3c-0.1,5-4.3,9.1-9.3,9.1c-5,0-9.2-4.3-9.1-9.3  C76.2,122.8,80.5,118.7,85.5,118.7L85.5,118.7z"
                                  />
                                  <path
                                    class="st0"
                                    fill="#E5097D"
                                    d="M148,127.7c0.1,4.9-4.1,9.2-9.1,9.3c-5.1,0.1-9.2-3.9-9.3-9c-0.1-5.1,3.9-9.2,9-9.3  C143.7,118.7,147.9,122.7,148,127.7L148,127.7z"
                                  />
                                </svg>
                              </Link>
                            )}
                            <span className="video_author_name">
                              <Link
                                to={`/profile/${item.userId.username}`}
                              >{`${item.userId.firstName} ${item.userId.lastName}`}</Link>
                            </span>
                          </div>
                          <div className="post_stats mb-0 p-0 pt-lg-1 mb-lg-0">
                            <span className="views">
                              {item.viewCount} <i className="fal fa-eye"></i>
                            </span>
                            <span className="comments">
                              {item.comments?.length}{" "}
                              <i className="fal fa-comment-alt"></i>
                            </span>
                            <span
                              className="likes d-inline-flex align-items-center gap-1"
                              onClick={() => handleLikePost(item._id, true)}
                              style={{ cursor: "pointer" }}
                            >
                              {item.likeCount}
                              {isLiked_2[item._id] ? (
                                <FontAwesomeIcon
                                  icon={faHeartFilled}
                                  color="#e5097d"
                                />
                              ) : (
                                <FontAwesomeIcon icon={faHeart} color="#000" />
                              )}
                            </span>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </SwiperSlide>
                ))}
              </Swiper>
            </section>
            {/* <Tab.Container
              activeKey={activeTab}
              onSelect={(e) => {
                setActiveTab(e);
                fetchTrendingPosts(e);
              }}
            >
              <Nav
                role="tablist"
                className="nav nav-tabs border-none justify-content-start "
                id="trendingPostTabs"
                as="ul"
              >
                {trendingHashtags?.map(({ hashtag }, index) => (
                  <Nav.Item
                    key={index}
                    role="presentation"
                    as="li"
                    className="nav-item"
                  >
                    <Nav.Link
                      eventKey={hashtag}
                      className="nav-link"
                      role="tab"
                      aria-controls={hashtag}
                      aria-selected="true"
                    >
                      {hashtag}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <Tab.Content className="tab-content" id="trendingPostTabsContent">
                {trendingHashtags?.map(({ hashtag, postCount }, index) => (
                  <Tab.Pane
                    key={index}
                    eventKey={hashtag}
                    role="tabpanel"
                    aria-labelledby={hashtag}
                  >
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container> */}
          </Container>
        </section>
        <section className="popular_sec  py-2 py-lg-3">
          <Container>
            <h1 className="m-0 mb-lg-2">
              <Link to="/latest-posts">
                <img
                  src={PublicImages.PopularPost}
                  className="img-fluid"
                  alt=""
                />
                Latest Posts
              </Link>
            </h1>

            {popularPostLoading ? (
              <Loader loading={popularPostLoading} />
            ) : (
              <Swiper
                navigation={false}
                spaceBetween={20}
                loop={true}
                pagination={true}
                autoHeight={true}
                // loopAdditionalSlides={1}
                autoplay={{ delay: 5000, disableOnInteraction: false }}
                modules={[Autoplay, Pagination]}
                breakpoints={{
                  // For mobile devices
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                    // slidesPerGroup: 2,
                  },
                  640: {
                    slidesPerView: 2,
                  },
                  // For desktop and larger screens
                  1024: {
                    slidesPerView: 4,
                  },
                }}
              >
                {posts?.length > 0 &&
                  posts.map((item, index) => (
                    <SwiperSlide key={index} className="item">
                      <Card className="w-100 m-0">
                        {item.isStarmarked && (
                          <Link
                            to={`/${item?.productId?.slug}/challenges/${item.challengeId?.slug}`}
                            className="btn-default-2 addToBookmarkButton"
                            style={{
                              right: item.isWinner ? 105 : 57,
                            }}
                          >
                            <Image
                              src={AdminImages.StarmarkGold}
                              height={30}
                              width={30}
                              className="img-fluid"
                            />
                          </Link>
                        )}
                        {item.isWinner && (
                          <Link
                            to={`/${item?.productId?.slug}/challenges/${item.challengeId?.slug}`}
                            className="btn-default-2 addToBookmarkButton"
                            style={{
                              right: 57,
                            }}
                          >
                            <Image
                              src={PublicImages.Trophy}
                              height={30}
                              width={30}
                              className="img-fluid"
                            />
                          </Link>
                        )}
                        <Button
                          className="btn-default-2 addToBookmarkButton"
                          onClick={() => handleBookmarkPost(item._id)}
                        >
                          {isBookmark[item._id] ? (
                            <FontAwesomeIcon icon={Bookmarked} />
                          ) : (
                            <FontAwesomeIcon icon={notBookmarked} />
                          )}
                        </Button>
                        <Link
                          to={`/${item?.productId?.slug}/${item?.categoryId?.slug}/${item.slug}`}
                          className="d-block w-100"
                        >
                          <LazyLoadImage
                            src={uploadURL + item?.featuredImage}
                            alt={item.title}
                            effect="blur"
                            className="card-img card-img-top w-100"
                            delayTime={5000}
                            wrapperClassName="d-block w-100"
                          />
                        </Link>
                        <Card.Body className="text-center pb-0 pb-lg-auto">
                          <div className="post_info d-flex align-items-center justify-content-center ">
                            <span className="post_cat">
                              <Link
                                to={`/${item?.productId?.slug}/${item?.categoryId?.slug}?subcategory=${item.subCategoryId?.slug}`}
                              >
                                {item.subCategoryId?.name}
                              </Link>
                            </span>
                            <span className="post_created_on">
                              {moment(item.createdAt).format("D-M-Y")}
                            </span>
                          </div>
                          <Card.Title className="post_title text-center">
                            <Link
                              to={`/${item?.productId?.slug}/${item?.categoryId?.slug}/${item.slug}`}
                            >
                              {item.title}
                            </Link>
                          </Card.Title>
                          <div className="post_author_info_cover  flex-column-reverse flex-lg-row justify-content-center">
                            <div className="post_author_info d-flex gap-2 align-items-center justify-content-center mb-2">
                              {item.userId?.profileImage ? (
                                <Link to={`/profile/${item.userId.username}`}>
                                  <LazyLoadImage
                                    src={uploadURL + item.userId.profileImage}
                                    alt={item.userId.username}
                                    effect="blur"
                                    className="card-img img-fluid author_img"
                                    delayTime={5000}
                                    wrapperClassName="d-block"
                                    width="32px"
                                    height="32px"
                                  />
                                </Link>
                              ) : (
                                <Link to={`/profile/${item.userId.username}`}>
                                  <svg
                                    width="32px"
                                    height="32px"
                                    xmlns="http://www.w3.org/2000/svg"
                                    version="1.1"
                                    id="Layer_1"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 224.2 319.9"
                                  >
                                    <path
                                      class="st0"
                                      fill="#E5097D"
                                      d="M48.1,107.5c-4.9-1.5-7.2-6.3-5-10c0.6-1,2.8-1.5,4.2-1.5c14.8-0.1,29.7-0.1,44.5-0.1  c15.7,0,22.3-5.1,26.5-20.1c3-10.7,5.9-21.5,9.4-32.1c0.8-2.4,3.3-5.4,5.6-5.9c3.8-0.9,5.2,2.8,6.1,5.9c3.6,12.5,6.9,25,10.6,37.5  c3.3,11.2,8.2,14.7,20,14.8c1.7,0,3.3,0,5,0c6.3,0.1,8.3,2.5,6.3,8.5c-0.5,1.4-2.8,2.2-5.1,4c0,6.4-0.1,13.8,0,21.3  c0.4,24.1-9.1,43.2-29,57c-1.6,1.1-2.7,3.7-2.8,5.7c-0.3,7.1,0,14.3-0.2,21.5c-0.1,3.7,1,4.9,4.8,4.8c11.8-0.2,23.7-0.1,35.5-0.1  c24,0.1,39.7,15.8,39.8,39.8c0.1,17.5,0,35,0,52.5c0,7.5-1.5,9-9.1,9c-68.6,0-137.3,0-205.9,0c-7.8,0-9.1-1.2-9.1-8.9  c0-17.7-0.1-35.3,0-53c0.2-20.6,13-36.6,31.6-38.8c8.2-1,16.6-0.5,25-0.5c6.5-0.1,13-0.1,19.5,0c2.8,0.1,3.9-1,3.8-3.8  c-0.1-7.8,0.1-15.7-0.2-23.5c-0.1-1.6-1.4-3.8-2.8-4.7c-20.2-14-29.4-33.3-28.9-57.6C48.2,122,48.1,114.8,48.1,107.5L48.1,107.5z   M212.6,308.9c0.3-0.7,0.6-1.2,0.6-1.6c0-18.2,0.4-36.3-0.2-54.5c-0.4-11.8-10.7-22.2-22.7-22.9c-15.6-0.8-31.3-0.6-46.9-0.5  c-1.7,0-3.7,1.4-5,2.6c-6.9,6.7-13.7,13.6-20.5,20.5c-4.6,4.5-6.8,4.5-11.3,0.1c-6.9-6.8-13.6-13.7-20.6-20.4  c-1.5-1.5-3.9-2.8-5.9-2.8c-13.8-0.2-27.7-0.4-41.5,0C21,229.9,11,240.6,10.9,258.1c-0.1,15.2,0,30.3,0,45.5c0,1.8,0.2,3.5,0.3,5.3  L212.6,308.9L212.6,308.9z M134,64.7l-1.3,0.2c-1.9,6.5-3.6,13.1-5.7,19.5c-3.9,12.4-14.6,21.1-27.7,21.7c-11.5,0.6-23,0.5-34.4,0.2  c-4.7-0.1-6.7,1.1-6.3,6.1c0.4,6,0.2,12,0.2,18c-0.2,30.8,20.3,53.3,50.9,55.7c22.7,1.8,46-14.4,52.8-36.2  c4.2-13.4,2.4-27.1,2.9-40.6c0-1-1.4-2.9-2.4-3.1c-14-2.1-20.9-11.1-23.9-24C137.6,76.3,135.7,70.6,134,64.7L134,64.7z M112.1,243.6  c7.1-7.3,13.4-13.7,19.6-20.2c0.7-0.7,1.4-1.7,1.4-2.5c0.1-8.9,0.1-17.8,0.1-27.3c-14.4,4.8-28.1,4.5-42.3,0.1c0,9.3,0,18,0.1,26.6  c0,0.9,0.4,2,1.1,2.7C98.4,229.5,104.9,236.2,112.1,243.6z"
                                    />
                                    <path
                                      class="st0"
                                      fill="#E5097D"
                                      d="M192,212.6c0-10.7,0-21.2,0-31.7c0-29.5,0-59,0-88.5c0.1-41.8-28.1-75.3-69.5-81C77.4,5.3,38.8,37,33.5,77.9  c-2.4,18.7-1.3,37.9-1.4,56.9c-0.2,24.3-0.1,48.6,0.1,73c0,4.3-1,5.7-5.5,5.8c-4.7,0-5.4-1.9-5.4-6c0.1-38.3,0-76.6,0.1-114.9  c0.1-41,24.7-75.8,62.2-88C134.8-12,188.7,18,200.2,70.6c3,13.6,2.4,28.1,2.5,42.2c0.3,30.6,0.1,61.3,0,92  C202.8,214.2,202.5,214.4,192,212.6L192,212.6z"
                                    />
                                    <path
                                      class="st0"
                                      fill="#E5097D"
                                      d="M131.8,155.1c-0.5,11.2-9.2,19.6-19.7,19.5c-10.7,0-19-8.1-19.8-19.4c4.1-0.9,7.6-1.9,9.3,4.1  c1.3,4.6,5.6,6.9,10.5,6.9s9.3-2.1,10.5-6.8C124.1,153.7,127.5,154.4,131.8,155.1L131.8,155.1z"
                                    />
                                    <path
                                      class="st0"
                                      fill="#E5097D"
                                      d="M85.5,118.7c5,0,9.2,4.2,9.1,9.3c-0.1,5-4.3,9.1-9.3,9.1c-5,0-9.2-4.3-9.1-9.3  C76.2,122.8,80.5,118.7,85.5,118.7L85.5,118.7z"
                                    />
                                    <path
                                      class="st0"
                                      fill="#E5097D"
                                      d="M148,127.7c0.1,4.9-4.1,9.2-9.1,9.3c-5.1,0.1-9.2-3.9-9.3-9c-0.1-5.1,3.9-9.2,9-9.3  C143.7,118.7,147.9,122.7,148,127.7L148,127.7z"
                                    />
                                  </svg>
                                </Link>
                              )}
                              <span className="video_author_name">
                                <Link
                                  to={`/profile/${item.userId.username}`}
                                >{`${item.userId.firstName} ${item.userId.lastName}`}</Link>
                              </span>
                            </div>
                            <div className="post_stats mb-0 p-0 pt-lg-1 mb-lg-0">
                              <span className="views">
                                {item.viewCount} <i className="fal fa-eye"></i>
                              </span>
                              <span className="comments">
                                {item.comments?.length}{" "}
                                <i className="fal fa-comment-alt"></i>
                              </span>
                              <span
                                className="likes d-inline-flex align-items-center gap-1"
                                onClick={() => handleLikePost(item._id, true)}
                                style={{ cursor: "pointer" }}
                              >
                                {item.likeCount}
                                {isLiked_2[item._id] ? (
                                  <FontAwesomeIcon
                                    icon={faHeartFilled}
                                    color="#e5097d"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    icon={faHeart}
                                    color="#000"
                                  />
                                )}
                              </span>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </SwiperSlide>
                  ))}
              </Swiper>
            )}
          </Container>
        </section>
        <section className="categories_sec py-2 py-lg-3">
          <Container>
            <h1 className="m-0 mb-lg-2">
              <Link
                reloadDocument={true}
                to={`/allChallenges/`}
                className="text-decoration-none text-dark d-block  w-100"
                style={{
                  cursor: "pointer",
                  position: "relative",
                }}
              >
                <Image src={PublicImages.pages} fluid alt="" />
                {getValueByKey(languagesettings, "home_products_label")}
              </Link>
            </h1>
            {challengesLoading ? (
              <Loader loading={challengesLoading} />
            ) : (
              <>
                {challenges.length > 0 ? (
                  <Swiper
                    // navigation={true} // Show navigation arrows
                    // spaceBetween={20}
                    // loop={true}
                    // pagination={true}
                    // autoHeight={true}
                    // // loopAdditionalSlides={1}
                    // autoplay={{ delay: 5000, disableOnInteraction: false }}
                    // modules={[Autoplay, Pagination]}
                    // breakpoints={{
                    //   // For mobile devices
                    //   320: {
                    //     slidesPerView: 1,
                    //     spaceBetween: 0,
                    //     pagination: false,
                    //   },
                    //   640: {
                    //     slidesPerView: 2,
                    //   },
                    //   // For desktop and larger screens
                    //   1024: {
                    //     slidesPerView: 4,
                    //   },
                    // }}
                    navigation={false}
                    spaceBetween={20}
                    // loop={true}
                    pagination={true}
                    autoHeight={true}
                    // loopAdditionalSlides={1}
                    autoplay={{ delay: 5000, disableOnInteraction: false }}
                    modules={[Autoplay, Pagination]}
                    breakpoints={{
                      // For mobile devices
                      320: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                        // slidesPerGroup: 2,
                      },
                      640: {
                        slidesPerView: 2,
                      },
                      // For desktop and larger screens
                      1024: {
                        slidesPerView: 4,
                      },
                    }}
                  >
                    {challenges.map(
                      (
                        { slug, photo, name, challengeEndDate, productId },
                        index
                      ) => (
                        <SwiperSlide key={index}>
                          <Card className="card_style_2 border-0">
                            <Link
                              reloadDocument={true}
                              to={`/${productId?.slug}/challenges/`}
                              className="text-decoration-none text-dark d-block  w-100"
                              style={{
                                cursor: "pointer",
                                position: "relative",
                              }}
                            >
                              <LazyLoadImage
                                effect="blur"
                                src={uploadURL + photo}
                                className="img-fluid w-100 challengeImage"
                                wrapperClassName="d-block"
                                alt=""
                              />
                              {challengeEndDate &&
                                moment(challengeEndDate).isSameOrAfter(
                                  moment(new Date()).format("YYYY-MM-DD")
                                ) && (
                                  <span className="badge badge-primary challenge_live">
                                    <FontAwesomeIcon
                                      icon={faCircle}
                                      color="#fff"
                                      className={`pe-2 fa-beat Blink`}
                                    />
                                    Live
                                  </span>
                                )}

                              <Card.Body className="text-center pb-0 pb-lg-auto">
                                <Card.Title>
                                  <p>
                                    <Link
                                      reloadDocument={true}
                                      to={`/${productId?.slug}/challenges/`}
                                      className="text-decoration-none text-dark d-block  w-100"
                                      style={{
                                        cursor: "pointer",
                                        position: "relative",
                                      }}
                                    >
                                      {name}
                                    </Link>
                                  </p>
                                </Card.Title>
                              </Card.Body>
                            </Link>
                          </Card>
                        </SwiperSlide>
                      )
                    )}
                  </Swiper>
                ) : (
                  <Row>
                    <Col className="text-center" xs={12}>
                      <p>No Challeges Added Yet!</p>
                    </Col>
                  </Row>
                )}
              </>
            )}
          </Container>
        </section>
        <section className="creators_sec  py-2 py-lg-3">
          <Container>
            <h1 className="mb-1 mb-lg-4 p-0 ">
              <svg
                width="50px"
                height="50px"
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 224.2 319.9"
              >
                <path
                  class="st0"
                  fill="#E5097D"
                  d="M48.1,107.5c-4.9-1.5-7.2-6.3-5-10c0.6-1,2.8-1.5,4.2-1.5c14.8-0.1,29.7-0.1,44.5-0.1  c15.7,0,22.3-5.1,26.5-20.1c3-10.7,5.9-21.5,9.4-32.1c0.8-2.4,3.3-5.4,5.6-5.9c3.8-0.9,5.2,2.8,6.1,5.9c3.6,12.5,6.9,25,10.6,37.5  c3.3,11.2,8.2,14.7,20,14.8c1.7,0,3.3,0,5,0c6.3,0.1,8.3,2.5,6.3,8.5c-0.5,1.4-2.8,2.2-5.1,4c0,6.4-0.1,13.8,0,21.3  c0.4,24.1-9.1,43.2-29,57c-1.6,1.1-2.7,3.7-2.8,5.7c-0.3,7.1,0,14.3-0.2,21.5c-0.1,3.7,1,4.9,4.8,4.8c11.8-0.2,23.7-0.1,35.5-0.1  c24,0.1,39.7,15.8,39.8,39.8c0.1,17.5,0,35,0,52.5c0,7.5-1.5,9-9.1,9c-68.6,0-137.3,0-205.9,0c-7.8,0-9.1-1.2-9.1-8.9  c0-17.7-0.1-35.3,0-53c0.2-20.6,13-36.6,31.6-38.8c8.2-1,16.6-0.5,25-0.5c6.5-0.1,13-0.1,19.5,0c2.8,0.1,3.9-1,3.8-3.8  c-0.1-7.8,0.1-15.7-0.2-23.5c-0.1-1.6-1.4-3.8-2.8-4.7c-20.2-14-29.4-33.3-28.9-57.6C48.2,122,48.1,114.8,48.1,107.5L48.1,107.5z   M212.6,308.9c0.3-0.7,0.6-1.2,0.6-1.6c0-18.2,0.4-36.3-0.2-54.5c-0.4-11.8-10.7-22.2-22.7-22.9c-15.6-0.8-31.3-0.6-46.9-0.5  c-1.7,0-3.7,1.4-5,2.6c-6.9,6.7-13.7,13.6-20.5,20.5c-4.6,4.5-6.8,4.5-11.3,0.1c-6.9-6.8-13.6-13.7-20.6-20.4  c-1.5-1.5-3.9-2.8-5.9-2.8c-13.8-0.2-27.7-0.4-41.5,0C21,229.9,11,240.6,10.9,258.1c-0.1,15.2,0,30.3,0,45.5c0,1.8,0.2,3.5,0.3,5.3  L212.6,308.9L212.6,308.9z M134,64.7l-1.3,0.2c-1.9,6.5-3.6,13.1-5.7,19.5c-3.9,12.4-14.6,21.1-27.7,21.7c-11.5,0.6-23,0.5-34.4,0.2  c-4.7-0.1-6.7,1.1-6.3,6.1c0.4,6,0.2,12,0.2,18c-0.2,30.8,20.3,53.3,50.9,55.7c22.7,1.8,46-14.4,52.8-36.2  c4.2-13.4,2.4-27.1,2.9-40.6c0-1-1.4-2.9-2.4-3.1c-14-2.1-20.9-11.1-23.9-24C137.6,76.3,135.7,70.6,134,64.7L134,64.7z M112.1,243.6  c7.1-7.3,13.4-13.7,19.6-20.2c0.7-0.7,1.4-1.7,1.4-2.5c0.1-8.9,0.1-17.8,0.1-27.3c-14.4,4.8-28.1,4.5-42.3,0.1c0,9.3,0,18,0.1,26.6  c0,0.9,0.4,2,1.1,2.7C98.4,229.5,104.9,236.2,112.1,243.6z"
                />
                <path
                  class="st0"
                  fill="#E5097D"
                  d="M192,212.6c0-10.7,0-21.2,0-31.7c0-29.5,0-59,0-88.5c0.1-41.8-28.1-75.3-69.5-81C77.4,5.3,38.8,37,33.5,77.9  c-2.4,18.7-1.3,37.9-1.4,56.9c-0.2,24.3-0.1,48.6,0.1,73c0,4.3-1,5.7-5.5,5.8c-4.7,0-5.4-1.9-5.4-6c0.1-38.3,0-76.6,0.1-114.9  c0.1-41,24.7-75.8,62.2-88C134.8-12,188.7,18,200.2,70.6c3,13.6,2.4,28.1,2.5,42.2c0.3,30.6,0.1,61.3,0,92  C202.8,214.2,202.5,214.4,192,212.6L192,212.6z"
                />
                <path
                  class="st0"
                  fill="#E5097D"
                  d="M131.8,155.1c-0.5,11.2-9.2,19.6-19.7,19.5c-10.7,0-19-8.1-19.8-19.4c4.1-0.9,7.6-1.9,9.3,4.1  c1.3,4.6,5.6,6.9,10.5,6.9s9.3-2.1,10.5-6.8C124.1,153.7,127.5,154.4,131.8,155.1L131.8,155.1z"
                />
                <path
                  class="st0"
                  fill="#E5097D"
                  d="M85.5,118.7c5,0,9.2,4.2,9.1,9.3c-0.1,5-4.3,9.1-9.3,9.1c-5,0-9.2-4.3-9.1-9.3  C76.2,122.8,80.5,118.7,85.5,118.7L85.5,118.7z"
                />
                <path
                  class="st0"
                  fill="#E5097D"
                  d="M148,127.7c0.1,4.9-4.1,9.2-9.1,9.3c-5.1,0.1-9.2-3.9-9.3-9c-0.1-5.1,3.9-9.2,9-9.3  C143.7,118.7,147.9,122.7,148,127.7L148,127.7z"
                />
              </svg>
              {/* <img src={PublicImages.Creator} alt="" /> */}
              <Link to="/top-contributors">CREATORS</Link>
            </h1>

            {topCreatorsLoading ? (
              <Loader loading={topCreatorsLoading} />
            ) : (
              <Swiper
                navigation={false}
                spaceBetween={20}
                loop={true}
                loopAdditionalSlides={1}
                autoplay={{ delay: 3000, disableOnInteraction: false }}
                modules={[Autoplay]}
                breakpoints={{
                  // For mobile devices
                  320: {
                    slidesPerView: 1,
                  },
                  640: {
                    slidesPerView: 2,
                  },
                  // For desktop and larger screens
                  1024: {
                    slidesPerView: 4,
                  },
                }}
              >
                {users?.length > 0 &&
                  users.map((item, index) => (
                    <SwiperSlide key={index} className="item">
                      <Card>
                        <Row className="g-0 w-100 align-items-center justify-content-center">
                          {item.userId?.profileImage ? (
                            <Col md={4} xs={12} sm={12} className="text-center">
                              <Link to={`/profile/${item.userId.username}`}>
                                <LazyLoadImage
                                  src={uploadURL + item.userId.profileImage}
                                  className="img-fluid"
                                  effect="blur"
                                  wrapperClassName="d-block"
                                  alt={`${item.userId.firstName} ${item.userId.lastName}`}
                                />
                              </Link>
                            </Col>
                          ) : (
                            <Col md={4} xs={12} sm={12} className="text-center">
                              <Link to={`/profile/${item.userId.username}`}>
                                {/* <svg
                                  width="87px"
                                  height="87px"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                  <g
                                    id="SVGRepo_tracerCarrier"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  ></g>
                                  <g id="SVGRepo_iconCarrier">
                                    <circle
                                      opacity="0.5"
                                      cx="12"
                                      cy="9"
                                      r="3"
                                      stroke="#e5097d"
                                      strokeWidth="1.5"
                                    ></circle>
                                    <circle
                                      cx="12"
                                      cy="12"
                                      r="10"
                                      stroke="#e5097d"
                                      strokeWidth="1.5"
                                    ></circle>
                                    <path
                                      opacity="0.5"
                                      d="M17.9691 20C17.81 17.1085 16.9247 15 11.9999 15C7.07521 15 6.18991 17.1085 6.03076 20"
                                      stroke="#e5097d"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                    ></path>
                                  </g>
                                </svg> */}
                                <svg
                                  width="87px"
                                  height="87px"
                                  xmlns="http://www.w3.org/2000/svg"
                                  version="1.1"
                                  id="Layer_1"
                                  x="0px"
                                  y="0px"
                                  viewBox="0 0 224.2 319.9"
                                >
                                  <path
                                    class="st0"
                                    fill="#E5097D"
                                    d="M48.1,107.5c-4.9-1.5-7.2-6.3-5-10c0.6-1,2.8-1.5,4.2-1.5c14.8-0.1,29.7-0.1,44.5-0.1  c15.7,0,22.3-5.1,26.5-20.1c3-10.7,5.9-21.5,9.4-32.1c0.8-2.4,3.3-5.4,5.6-5.9c3.8-0.9,5.2,2.8,6.1,5.9c3.6,12.5,6.9,25,10.6,37.5  c3.3,11.2,8.2,14.7,20,14.8c1.7,0,3.3,0,5,0c6.3,0.1,8.3,2.5,6.3,8.5c-0.5,1.4-2.8,2.2-5.1,4c0,6.4-0.1,13.8,0,21.3  c0.4,24.1-9.1,43.2-29,57c-1.6,1.1-2.7,3.7-2.8,5.7c-0.3,7.1,0,14.3-0.2,21.5c-0.1,3.7,1,4.9,4.8,4.8c11.8-0.2,23.7-0.1,35.5-0.1  c24,0.1,39.7,15.8,39.8,39.8c0.1,17.5,0,35,0,52.5c0,7.5-1.5,9-9.1,9c-68.6,0-137.3,0-205.9,0c-7.8,0-9.1-1.2-9.1-8.9  c0-17.7-0.1-35.3,0-53c0.2-20.6,13-36.6,31.6-38.8c8.2-1,16.6-0.5,25-0.5c6.5-0.1,13-0.1,19.5,0c2.8,0.1,3.9-1,3.8-3.8  c-0.1-7.8,0.1-15.7-0.2-23.5c-0.1-1.6-1.4-3.8-2.8-4.7c-20.2-14-29.4-33.3-28.9-57.6C48.2,122,48.1,114.8,48.1,107.5L48.1,107.5z   M212.6,308.9c0.3-0.7,0.6-1.2,0.6-1.6c0-18.2,0.4-36.3-0.2-54.5c-0.4-11.8-10.7-22.2-22.7-22.9c-15.6-0.8-31.3-0.6-46.9-0.5  c-1.7,0-3.7,1.4-5,2.6c-6.9,6.7-13.7,13.6-20.5,20.5c-4.6,4.5-6.8,4.5-11.3,0.1c-6.9-6.8-13.6-13.7-20.6-20.4  c-1.5-1.5-3.9-2.8-5.9-2.8c-13.8-0.2-27.7-0.4-41.5,0C21,229.9,11,240.6,10.9,258.1c-0.1,15.2,0,30.3,0,45.5c0,1.8,0.2,3.5,0.3,5.3  L212.6,308.9L212.6,308.9z M134,64.7l-1.3,0.2c-1.9,6.5-3.6,13.1-5.7,19.5c-3.9,12.4-14.6,21.1-27.7,21.7c-11.5,0.6-23,0.5-34.4,0.2  c-4.7-0.1-6.7,1.1-6.3,6.1c0.4,6,0.2,12,0.2,18c-0.2,30.8,20.3,53.3,50.9,55.7c22.7,1.8,46-14.4,52.8-36.2  c4.2-13.4,2.4-27.1,2.9-40.6c0-1-1.4-2.9-2.4-3.1c-14-2.1-20.9-11.1-23.9-24C137.6,76.3,135.7,70.6,134,64.7L134,64.7z M112.1,243.6  c7.1-7.3,13.4-13.7,19.6-20.2c0.7-0.7,1.4-1.7,1.4-2.5c0.1-8.9,0.1-17.8,0.1-27.3c-14.4,4.8-28.1,4.5-42.3,0.1c0,9.3,0,18,0.1,26.6  c0,0.9,0.4,2,1.1,2.7C98.4,229.5,104.9,236.2,112.1,243.6z"
                                  />
                                  <path
                                    class="st0"
                                    fill="#E5097D"
                                    d="M192,212.6c0-10.7,0-21.2,0-31.7c0-29.5,0-59,0-88.5c0.1-41.8-28.1-75.3-69.5-81C77.4,5.3,38.8,37,33.5,77.9  c-2.4,18.7-1.3,37.9-1.4,56.9c-0.2,24.3-0.1,48.6,0.1,73c0,4.3-1,5.7-5.5,5.8c-4.7,0-5.4-1.9-5.4-6c0.1-38.3,0-76.6,0.1-114.9  c0.1-41,24.7-75.8,62.2-88C134.8-12,188.7,18,200.2,70.6c3,13.6,2.4,28.1,2.5,42.2c0.3,30.6,0.1,61.3,0,92  C202.8,214.2,202.5,214.4,192,212.6L192,212.6z"
                                  />
                                  <path
                                    class="st0"
                                    fill="#E5097D"
                                    d="M131.8,155.1c-0.5,11.2-9.2,19.6-19.7,19.5c-10.7,0-19-8.1-19.8-19.4c4.1-0.9,7.6-1.9,9.3,4.1  c1.3,4.6,5.6,6.9,10.5,6.9s9.3-2.1,10.5-6.8C124.1,153.7,127.5,154.4,131.8,155.1L131.8,155.1z"
                                  />
                                  <path
                                    class="st0"
                                    fill="#E5097D"
                                    d="M85.5,118.7c5,0,9.2,4.2,9.1,9.3c-0.1,5-4.3,9.1-9.3,9.1c-5,0-9.2-4.3-9.1-9.3  C76.2,122.8,80.5,118.7,85.5,118.7L85.5,118.7z"
                                  />
                                  <path
                                    class="st0"
                                    fill="#E5097D"
                                    d="M148,127.7c0.1,4.9-4.1,9.2-9.1,9.3c-5.1,0.1-9.2-3.9-9.3-9c-0.1-5.1,3.9-9.2,9-9.3  C143.7,118.7,147.9,122.7,148,127.7L148,127.7z"
                                  />
                                </svg>
                              </Link>
                            </Col>
                          )}
                          <Col md={8} xs={12} sm={12} className="">
                            <Card.Body className="px-0">
                              <h5 className="card-title text-center text-md-start">
                                <Link to={`/profile/${item.userId.username}`}>
                                  {/* {item.userId.username} */}
                                  {item.userId.firstName} {item.userId.lastName}
                                </Link>
                              </h5>
                              <div className="d-flex gap-3 userStats justify-content-center justify-content-md-start">
                                <div className="d-flex gap-1 align-items-center postCount">
                                  <i className="fal fa-file-signature"></i>
                                  {item.userId.postCount}
                                </div>
                                <div className="d-flex gap-1 align-items-center awardedBadges">
                                  <i className="fal fa-badge-check"></i>
                                  {item.userId.awardedBadges.length}
                                </div>
                                <div className="d-flex gap-1 align-items-center followers">
                                  <i className="fal fa-user-friends"></i>
                                  {item?.userPreferences?.followers?.length}
                                </div>

                                <Tooltip
                                  anchorSelect=".postCount"
                                  place="bottom"
                                >
                                  Total Posts
                                </Tooltip>
                                <Tooltip
                                  anchorSelect=".awardedBadges"
                                  place="bottom"
                                >
                                  Badges
                                </Tooltip>
                                <Tooltip
                                  anchorSelect=".followers"
                                  place="bottom"
                                >
                                  Followers
                                </Tooltip>
                              </div>
                            </Card.Body>
                          </Col>
                        </Row>
                      </Card>
                    </SwiperSlide>
                  ))}
              </Swiper>
            )}
          </Container>
        </section>
      </>
    );
  };
  return (
    <Layout>
      <HomePage languagesettings={languagesettings} />
    </Layout>
  );
}

export default Home;
