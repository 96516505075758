import React, { useEffect, useRef, useCallback, useMemo } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import { Mention } from "quill-mention";
import CustomMentionBlot from "./CustomMentionBlot"; // Import your custom blot
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAt, faHashtag } from "@fortawesome/free-solid-svg-icons";
import { Counter } from "./WordCounter";

Quill.register("modules/mention", Mention);
Quill.register("modules/hashtag", Mention);
// Quill.register(MentionBlot);
Quill.register(CustomMentionBlot);

const EditorWithMentionsAndHashtags = ({
  initialContent = "",
  placeholder = "",
  onContentChange = () => {},
  isReady = false,
  isMentionAndHashtag = true,
  editorController = "quill-toolbar",
  wordCounts = () => {},
}) => {
  const editorRef = useRef(null);
  const quillRef = useRef(null);

  const fetchMentions = useCallback(async (searchTerm) => {
    try {
      const response = await fetch(
        `https://womanii.com/api/public/search/users?search=${searchTerm}&page=1&limit=10`
      );
      const data = await response.json();
      return data.result
        .filter((user) => user.tagMe)
        .map((user) => ({
          id: user.username,
          value: `${user.firstName} ${user.lastName}`,
          profileImage: `https://womanii-bucket.s3.amazonaws.com/${user.profileImage}`,
        }));
    } catch (error) {
      console.error("Error fetching mentions:", error);
      return [];
    }
  }, []);

  const renderMentionItem = useCallback((item) => {
    const container = document.createElement("div");
    container.style.display = "flex";
    container.style.alignItems = "center";
    container.style.padding = "4px";

    if (item.profileImage) {
      const img = document.createElement("img");
      img.src = item.profileImage;
      img.alt = item.value;
      img.style.width = "24px";
      img.style.height = "24px";
      img.style.borderRadius = "50%";
      img.style.marginRight = "8px";
      container.appendChild(img);
    }

    const text = document.createElement("span");
    text.textContent = item.value;
    container.appendChild(text);

    return container;
  }, []);

  const handlePasteContent = useCallback(async () => {
    try {
      const permission = await navigator.permissions.query({
        name: "clipboard-read",
      });
      if (permission.state === "granted" || permission.state === "prompt") {
        const clipboardData = await navigator.clipboard.readText();
        quillRef.current.clipboard.dangerouslyPasteHTML(clipboardData);
      } else {
        alert("Clipboard permission denied");
      }
    } catch (error) {
      console.error("Error accessing clipboard:", error);
    }
  }, []);

  const handleAddMention = useCallback(() => {
    const cursorPosition = quillRef.current.getSelection()?.index || 0;
    quillRef.current.insertText(cursorPosition, "@");
    quillRef.current.setSelection(cursorPosition + 1); // Move cursor after the `@`
  }, []);

  const handleAddHashtag = useCallback(() => {
    const cursorPosition = quillRef.current.getSelection()?.index || 0;
    quillRef.current.insertText(cursorPosition, "#");
    quillRef.current.setSelection(cursorPosition + 1); // Move cursor after the `#`
  }, []);

  const modules = useMemo(() => {
    if (isMentionAndHashtag) {
      return {
        toolbar: `#${editorController}`,
        mention: {
          mentionDenotationChars: ["@"],
          allowedChars: /^[a-zA-Z0-9_]*$/,
          positioningStrategy: "fixed",
          source: async (searchTerm, renderList) => {
            const mentions = await fetchMentions(searchTerm);
            renderList(mentions, searchTerm);
          },
          renderItem: renderMentionItem,
          renderLoading: () => "Loading...",
          onSelect: (item, insertItem) => {
            insertItem(item);
            quillRef.current.focus();
          },
        },
        hashtag: {
          mentionDenotationChars: ["#"],
          allowedChars: /^[a-zA-Z0-9_]*$/,
          positioningStrategy: "fixed",
          source: (searchTerm, renderList) => {
            renderList([{ id: searchTerm, value: searchTerm }], searchTerm);
          },
          renderItem: (item) => item.value,
          onSelect: (item, insertItem) => {
            insertItem(item);
            quillRef.current.focus();
          },
        },
      };
    }

    return {
      toolbar: `#${editorController}`,
    };
  }, [isMentionAndHashtag, fetchMentions, renderMentionItem]);

  const initializeQuill = useCallback(() => {
    quillRef.current = new Quill(editorRef.current, {
      theme: "snow",
      modules,
      placeholder,
    });

    if (initialContent) {
      quillRef.current.clipboard.dangerouslyPasteHTML(initialContent);
    }

    quillRef.current.on("text-change", () => {
      const html = editorRef.current.querySelector(".ql-editor").innerHTML;
      onContentChange(html);
    });
  }, [initialContent, modules, onContentChange]);
  useEffect(() => {
    initializeQuill();
    Counter(
      quillRef.current,
      { container: "#word-counter", unit: "word" },
      (count) => {
        wordCounts(count);
      }
    );
  }, []);

  return (
    <div>
      <div id={editorController}>
        <button className="ql-bold"></button>
        <button className="ql-italic"></button>
        <button className="ql-underline"></button>
        <button className="ql-pasteButton" onClick={handlePasteContent}>
          Paste
        </button>
        {isMentionAndHashtag && false && (
          <>
            <button className="ql-addMention" onClick={handleAddMention}>
              <FontAwesomeIcon icon={faAt} color="#000" />
            </button>
            <button className="ql-addHashtag" onClick={handleAddHashtag}>
              <FontAwesomeIcon icon={faHashtag} color="#000" />
            </button>
          </>
        )}
      </div>
      <div ref={editorRef} className="editor-container" translate="no" />
      <div id="word-counter" className="mt-3">
        <b>Word Count: 0</b>
      </div>
    </div>
  );
};

export default EditorWithMentionsAndHashtags;
