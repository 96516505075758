import React, { useState } from "react";
import { Button, Container, Form, Ratio } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { getRequest, postRequestForm } from "../../../helper/api";
import { getItemFromLocalStorage } from "../../../helper/helper";
import Layout from "../../../layouts/admin/Layout";

import DateTimePicker from "react-datetime-picker";
import { useNavigate } from "react-router";
import { AsyncPaginate } from "react-select-async-paginate";
import EditorWithMentionsAndHashtags from "../../../components/Editor/EditorWithMentionsAndHashtags";
import Loader from "../../../components/Loader";
import PublicImages from "../../../constants/public/images";
import ReactSelect from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const SendEmailNotification = () => {
  const navigate = useNavigate();
  const [featuredFile, setFeaturedFile] = useState([]);
  const [status, setStatus] = useState();
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [topicOfInterest, setTopicOfInterest] = useState();
  const [user, setUser] = useState();
  const [pending, setPending] = useState(false);
  const [startDate, setStartDate] = useState(new Date());

  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "deactive", label: "Deactive" },
  ];
  const statusChange = (selectedOption) => {
    setStatus(selectedOption);
  };
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 5,
    accept: {
      "image/*": [],
      "video/mp4": [".mp4", ".MP4"],
      "audio/*": [],
      "application/pdf": [".pdf"],
      // "application/msword": [".doc", ".docx"],
    },
    onDrop: (acceptedFiles) => {
      setFeaturedFile(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setPending(true);

      // Prepare the data to be sent
      const formData = new FormData();
      if (subject) {
        formData.append("subject", subject);
      } else {
        toast.error("Please enter subject", {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
      if (content) {
        formData.append("message", content);
      } else {
        toast.error("Please enter message", {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
      if (featuredFile && featuredFile?.length > 0) {
        featuredFile.map((file) => {
          formData.append("attachments", file);
        });
      }
      if (startDate) {
        console.log("startDate", startDate.toISOString());
        console.log("startDate", startDate);
        formData.append("startDate", startDate.toISOString());
        formData.append("schedule", startDate.toISOString());
      } else {
        toast.error("Please select date to send", {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
      if (user || topicOfInterest) {
        if (topicOfInterest?.length) {
          topicOfInterest.forEach((topicOfInterest) => {
            formData.append("topicOfInterests[]", topicOfInterest?._id);
          });
        }
        if (user?.length) {
          user.forEach((user) => {
            formData.append("recipients[]", user?._id);
          });
        }
      } else {
        toast.error("Please select atleast one user or topic of interest", {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
      if (status) {
        formData.append("status", status.value);
      } else {
        toast.error("Please select status", {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        return false;
      }

      console.log("formData", Object.fromEntries(formData));
      const token = getItemFromLocalStorage("TOKEN");
      const response = await postRequestForm(
        "/api/secure/emailNotification/compose-email",
        token,
        formData
      );

      if (
        response?.result?.status === 200 ||
        response?.result?.status === 201
      ) {
        toast.success("Email Notification Created", {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        navigate("/EmailNotifications");
      }
    } catch (error) {
      console.log("Create Email Notification APi error", error.message);
    } finally {
      setPending(false);
    }
  };

  const fetchUsers = async (search, loadedOptions, { page }) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/user/get-all`,
        token,
        {
          page: page,
          search: search,
        }
      );
      if (result?.status === 200) {
        const currentPage = result?.data?.currentPage;
        const totalPages = result?.data?.totalPages;

        if (currentPage === totalPages) {
          return {
            options: result?.data?.users,
            hasMore: false,
          };
        } else {
          return {
            options: result?.data?.users,
            hasMore: result?.data?.users?.length >= 1,
            additional: {
              page: page + 1,
            },
          };
        }
      } else if (error?.response?.status === 400) {
        return {
          options: [],
          hasMore: false,
        };
      }
    } catch (error) {
      console.log("Get All Users Error", error);
    }
  };

  const fetchTopicOfIntrests = async (search, loadedOptions, { page }) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/topicOfInterest/get-all-topicOfInterest`,
        token,
        {
          page: page,
          search: search,
        }
      );
      if (result?.status === 200) {
        const currentPage = result?.data?.currentPage;
        const totalPages = result?.data?.totalPages;

        if (currentPage === totalPages) {
          return {
            options: result?.data?.topicOfInterests,
            hasMore: false,
          };
        } else {
          return {
            options: result?.data?.topicOfInterests,
            hasMore: result?.data?.topicOfInterests?.length >= 1,
            additional: {
              page: page + 1,
            },
          };
        }
      } else if (error?.response?.status === 400) {
        return {
          options: [],
          hasMore: false,
        };
      }
    } catch (error) {
      console.log("Get All Topic Of Interests Error", error);
    }
  };

  const handleChangeUsers = (selectedOptions) => {
    setUser(selectedOptions);
  };
  const handleChangeTopicOfInterests = (selectedOptions) => {
    setTopicOfInterest(selectedOptions);
  };

  const handleStartDate = (date) => {
    setStartDate(date);
  };

  const handleDescriptionChange = (content) => {
    setContent(content);
  };

  return (
    <Layout>
      <Container>
        {pending ? (
          <Loader loading={pending} />
        ) : (
          <div className="row mt-5">
            <div className="col-md-12">
              <h2 className="text-center mb-5">Compose Email</h2>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="subject">
                  <Form.Label>Email Subject</Form.Label>
                  <Form.Control
                    type="text"
                    value={subject}
                    required
                    onChange={(e) => setSubject(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="message">
                  <Form.Label>Email Message</Form.Label>

                  <EditorWithMentionsAndHashtags
                    initialContent={content}
                    placeholder={`Message of your Email Notification. A few sentences is usually recommended.`}
                    onContentChange={handleDescriptionChange}
                    isMentionAndHashtag={false}
                    editorController="quill-toolbar-1"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Date To Send</Form.Label>
                  <br />
                  <DateTimePicker
                    onChange={handleStartDate}
                    value={startDate}
                    format="d-MM-y h:mm:ss a"
                    minDate={new Date()}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Status</Form.Label>
                  <ReactSelect
                    placeholder="Select Status"
                    onChange={statusChange}
                    options={statusOptions}
                    value={status ? status : ""}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="userList">
                  <Form.Label>Users</Form.Label>
                  {/* <Select
                    options={users}
                    isMulti
                    onChange={handleChangeUsers}
                    onInputChange={(query) => {
                      getAllUsers(query);
                    }}
                  /> */}
                  <AsyncPaginate
                    value={user ? user : ""}
                    isMulti
                    loadOptions={fetchUsers}
                    getOptionLabel={(e) =>
                      `${e.firstName} ${e.lastName} (${e.email})`
                    }
                    getOptionValue={(e) => e.email}
                    onChange={handleChangeUsers}
                    placeholder="Select Users"
                    isClearable
                    loadingMessage={() => "Loading..."}
                    noOptionsMessage={() => "No User Found"}
                    additional={{
                      page: 1,
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="userList">
                  <Form.Label>Topic Of Intrests</Form.Label>
                  <AsyncPaginate
                    value={topicOfInterest ? topicOfInterest : ""}
                    isMulti
                    loadOptions={fetchTopicOfIntrests}
                    getOptionLabel={(e) => `${e.name}`}
                    getOptionValue={(e) => e._id}
                    onChange={handleChangeTopicOfInterests}
                    placeholder="Select Topic Of Intrests"
                    isClearable
                    loadingMessage={() => "Loading..."}
                    noOptionsMessage={() => "No Topic Of Intrests Found"}
                    additional={{
                      page: 1,
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Add Attachments (optional)</Form.Label>
                  {/* <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <p>
                      Please upload .MP4 format for video, .MP3 format for
                      audio, .PDF format for DOCS/Attachments and any type for
                      an image.
                    </p>
                  </div> */}
                  {featuredFile.length == 0 && (
                    <div className="mt-1 dot">
                      <div {...getRootProps({ className: "dropzone" })}>
                        <div className="d-flex">
                          <div className="p-2">
                            <img
                              src={PublicImages.backup}
                              className="img-fluid"
                              alt=""
                            />
                            <input {...getInputProps()} />
                          </div>
                          <div className="p-2">
                            <h5>Drop a file here or click to upload</h5>
                            <p>
                              Please upload .MP4 format for video, .MP3 format
                              for audio, .PDF format for DOCS/Attachments and
                              any type for an image.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="attachmetsPreview">
                    {featuredFile.map((file, key) => {
                      if (file.hasOwnProperty("data_url")) {
                        const fileExtension = file?.data_url?.split(".").pop(); // Get the file extension
                        if (fileExtension === "mp4") {
                          return (
                            <div
                              className="d-flex w-100 position-relative"
                              key={key}
                            >
                              <Ratio aspectRatio="16x9" key={key}>
                                <video controls>
                                  <source src={file.data_url} />
                                </video>
                              </Ratio>
                              <Button
                                onClick={(e) => {
                                  e.preventDefault();
                                  const filesRow = [...featuredFile];
                                  filesRow.splice(key, 1);
                                  setFeaturedFile(filesRow);
                                }}
                                className="clearVideoFile"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                }}
                              >
                                <FontAwesomeIcon icon={faTimes} />
                              </Button>
                            </div>
                          );
                        } else if (fileExtension === "mp3") {
                          return (
                            <div
                              className="d-flex gap-4 w-100 position-relative"
                              key={key}
                            >
                              <audio className="w-100" controls key={key}>
                                <source src={file.data_url} />
                              </audio>
                              <Button
                                onClick={(e) => {
                                  e.preventDefault();
                                  const filesRow = [...featuredFile];
                                  filesRow.splice(key, 1);
                                  setFeaturedFile(filesRow);
                                }}
                                className="clearAudioFile"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                }}
                              >
                                <FontAwesomeIcon icon={faTimes} />
                              </Button>
                            </div>
                          );
                        } else if (fileExtension === "pdf") {
                          return (
                            <div
                              className="d-flex w-100 position-relative"
                              key={key}
                            >
                              <embed
                                src={file.data_url}
                                type="application/pdf"
                                key={key}
                                width="100%"
                                height="200"
                              />
                              <Button
                                onClick={(e) => {
                                  e.preventDefault();
                                  const filesRow = [...featuredFile];
                                  filesRow.splice(key, 1);
                                  setFeaturedFile(filesRow);
                                }}
                                className="clearAudioFile"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                }}
                              >
                                <FontAwesomeIcon icon={faTimes} />
                              </Button>
                            </div>
                          );
                        } else {
                          return (
                            <div className="d-flex position-relative" key={key}>
                              <img
                                key={key}
                                src={file.data_url}
                                className="img-fluid"
                                height="200"
                              />
                              <Button
                                onClick={(e) => {
                                  e.preventDefault();
                                  const filesRow = [...featuredFile];
                                  filesRow.splice(key, 1);
                                  setFeaturedFile(filesRow);
                                }}
                                className="clearAudioFile"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                }}
                              >
                                <FontAwesomeIcon icon={faTimes} />
                              </Button>
                            </div>
                          );
                        }
                      } else {
                        if (file.type.startsWith("video/")) {
                          return (
                            <div
                              className="d-flex w-100 position-relative"
                              key={key}
                            >
                              <Ratio aspectRatio="16x9">
                                <video controls height="200">
                                  <source src={file.preview} type={file.type} />
                                </video>
                              </Ratio>
                              <Button
                                variant="danger"
                                size="sm"
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  const filesRow = [...featuredFile];
                                  filesRow.splice(key, 1);
                                  setFeaturedFile(filesRow);
                                }}
                              >
                                <FontAwesomeIcon icon={faTimes} />
                              </Button>
                            </div>
                          );
                        } else if (file.type.startsWith("audio/")) {
                          return (
                            <div
                              className="d-flex gap-4 w-100 position-relative"
                              key={key}
                            >
                              <audio controls>
                                <source src={file.preview} type={file.type} />
                              </audio>
                              <Button
                                variant="danger"
                                size="sm"
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  const filesRow = [...featuredFile];
                                  filesRow.splice(key, 1);
                                  setFeaturedFile(filesRow);
                                }}
                              >
                                <FontAwesomeIcon icon={faTimes} />
                              </Button>
                            </div>
                          );
                        } else if (file.type === "application/pdf") {
                          return (
                            <div
                              className="d-flex w-100 position-relative"
                              key={key}
                            >
                              <embed
                                src={file.preview}
                                type="application/pdf"
                                width="100%"
                                height="200"
                              />
                              <Button
                                variant="danger"
                                size="sm"
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  const filesRow = [...featuredFile];
                                  filesRow.splice(key, 1);
                                  setFeaturedFile(filesRow);
                                }}
                              >
                                <FontAwesomeIcon icon={faTimes} />
                              </Button>
                            </div>
                          );
                        } else if (file.type === "application/msword") {
                          // Handle DOC/DOCX files
                          return (
                            <div
                              className="d-flex w-100 position-relative"
                              key={key}
                            >
                              <a
                                href={file.preview}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {file.name}
                              </a>
                              <Button
                                variant="danger"
                                size="sm"
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  const filesRow = [...featuredFile];
                                  filesRow.splice(key, 1);
                                  setFeaturedFile(filesRow);
                                }}
                              >
                                <FontAwesomeIcon icon={faTimes} />
                              </Button>
                            </div>
                          );
                        } else {
                          return (
                            <div
                              className="d-flex w-100 position-relative"
                              key={key}
                            >
                              <img
                                src={file.preview}
                                className="img-fluid"
                                height="200"
                                key={key}
                              />
                              <Button
                                variant="danger"
                                size="sm"
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  const filesRow = [...featuredFile];
                                  filesRow.splice(key, 1);
                                  setFeaturedFile(filesRow);
                                }}
                              >
                                <FontAwesomeIcon icon={faTimes} />
                              </Button>
                            </div>
                          );
                        }
                      }
                    })}
                  </div>
                </Form.Group>
                <div className="text-center my-5">
                  <Button variant="primary" type="submit">
                    Send Email
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Container>
    </Layout>
  );
};

export default SendEmailNotification;
