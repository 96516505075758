export function Counter(quill, options, wordCounts) {
  // Ensure the container is provided and exists in the DOM
  const container = document.querySelector(options.container);
  if (!container) {
    console.error("Counter: Container element not found.");
    return;
  }

  const updateWordCount = () => {
    // Get the text from the editor, excluding formatting markers
    const text = quill.getText().trim(); // Use trim to remove trailing newlines

    console.log("Text content:", text);

    // Check the unit type and update the counter
    if (options.unit === "word") {
      const wordCount = text.length > 0 ? text.split(/\s+/).length : 0;
      console.log("Word count:", wordCount);
      container.innerHTML = `<b>Word Count: ${wordCount}</b>`;
      wordCounts(wordCount); // Return the word count via callback
    } else {
      container.innerText = `${text.length} characters`;
    }
  };

  // Initialize word count immediately
  updateWordCount();

  // Set up the text change event listener for dynamic updates
  quill.on("text-change", () => {
    updateWordCount();
  });
}
