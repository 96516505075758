import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState,useCallback } from "react";
import { Button, Col, Container, Form, Ratio, Row } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import { useDropzone } from "react-dropzone";
import ImageUploading from "react-images-uploading";
import { useLocation, useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import PublicImages from "../../../constants/public/images";
import { getRequest, putRequest, uploadURL } from "../../../helper/api";
import { getItemFromLocalStorage } from "../../../helper/helper";
import Layout from "../../../layouts/admin/Layout";
import { AsyncPaginate } from "react-select-async-paginate";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import PostEditor from "../../../components/admin/PostEditor";
import draftToHtml from "draftjs-to-html";
import EditorWithMentionsAndHashtags from "../../../components/Editor/EditorWithMentionsAndHashtags";
const EditPost = () => {
  let postSlug = "";
  const location = useLocation();
  const navigate = useNavigate();
  if (location?.state?.postSlug === "") {
    navigate("/SubSliders");
  } else {
    postSlug = location?.state?.postSlug;
  }
  const [shortStories, setShortStories] = useState(false);
  const [images, setImages] = useState([]);
  const [featuredFile, setFeaturedFile] = useState([]);
  const [featuredImage, setFeaturedImage] = useState("");
  const [status, setStatus] = useState();
  const [title, setTitle] = useState();
  const [subCategory, setSubCategory] = useState();
  const [product, setProduct] = useState();
  const [category, setCategory] = useState();
  const [users, setUsers] = useState();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);
  const [challenge, setChallenge] = useState("");
  const [description, setDescription] = useState("");
  const [mentions, setMentions] = useState([]);
  const [hashtags, setHashtags] = useState([]);
  const [reason, setReason] = useState("");
  const [linkToChallenge, setLinkToChallenge] = useState(false);
  const onChangeImage = async (imageList, addUpdateIndex) => {
    setImages(imageList);
    setFeaturedImage(imageList[addUpdateIndex].file);
  };

  const getLabelByValue = (options, value) => {
    const option = options?.find((option) => option.value === value);
    return option?.label;
  };
  const getWordCount = (text) => {
    const trimmedText = text?.trim();
    const words = trimmedText?.split(/\s+/);
    const nonEmptyWords = words?.filter((word) => word !== "");
    return nonEmptyWords?.length;
  };
  const fetchProducts = async (search, loadedOptions, { page }) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/product/get-all-products`,
        token,
        {
          page: page,
          search: search,
          status: "active",
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        const { currentPage, totalPages, products } = result?.data;
        if (currentPage === totalPages) {
          return {
            options: products,
            hasMore: false,
          };
        } else {
          return {
            options: products,
            hasMore: products?.length >= 1,
            additional: {
              page: page + 1,
            },
          };
        }
      } else if (error?.response?.status === 400) {
        return {
          options: [],
          hasMore: false,
        };
      }
    } catch (error) {
      console.log("Get All Products Error", error);
    }
  };
  const fetchCategories = async (search, loadedOptions, { page }) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/category/get-all-categories`,
        token,
        {
          page: page,
          search: search,
          status: "active",
          productType: product?._id,
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        const { currentPage, totalPages, categories } = result?.data;
        if (currentPage === totalPages) {
          return {
            options: categories,
            hasMore: false,
          };
        } else {
          return {
            options: categories,
            hasMore: categories?.length >= 1,
            additional: {
              page: page + 1,
            },
          };
        }
      } else if (error?.response?.status === 400) {
        return {
          options: [],
          hasMore: false,
        };
      }
    } catch (error) {
      console.log("Get All Sub Categories Error", error);
    }
  };
  const fetchSubCategories = async (search, loadedOptions, { page }) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/category/get-categories-from-master`,
        token,
        {
          page: page,
          search: search,
          status: "active",
          category: category?._id,
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        const { currentPage, totalPages, categories } = result?.data;
        if (currentPage === totalPages) {
          return {
            options: categories,
            hasMore: false,
          };
        } else {
          return {
            options: categories,
            hasMore: categories?.length >= 1,
            additional: {
              page: page + 1,
            },
          };
        }
      } else if (error?.response?.status === 400) {
        return {
          options: [],
          hasMore: false,
        };
      }
    } catch (error) {
      console.log("Get All Sub Categories Error", error);
    }
  };
  const fetchChallenges = async (search, loadedOptions, { page }) => {
    try {
      const token = getItemFromLocalStorage("TOKEN");
      const { result, error } = await getRequest(
        `/api/secure/challenge/get-all-challenges`,
        token,
        {
          page: page,
          search: search,
          status: "active",
          productId: product?._id,
          // categoryId: category?._id,
          // subCategoryId: subCategory?._id,
        }
      );
      if (result?.status === 200 || result?.status === 201) {
        const { currentPage, totalPages, challenges } = result?.data;
        if (currentPage === totalPages) {
          return {
            options: challenges,
            hasMore: false,
          };
        } else {
          return {
            options: challenges,
            hasMore: challenges?.length >= 1,
            additional: {
              page: page + 1,
            },
          };
        }
      } else if (error?.response?.status === 400) {
        return {
          options: [],
          hasMore: false,
        };
      }
    } catch (error) {
      console.log("Get All Challenges Error", error);
    }
  };
  const handleProductChange = (selectedOption) => {
    setProduct(selectedOption);
    setCategory();
    setSubCategory();
    setFeaturedFile([]);
    setDescription("");
  };
  const handleCategoryChange = (selectedOption) => {
    setCategory(selectedOption);
    setSubCategory();
  };
  const handleSubCategoryChange = (selectedOption) => {
    setSubCategory(selectedOption);
  };
  const hangleChallengeChange = (selectedOption) => {
    setChallenge(selectedOption);
    setSubCategory({
      _id: selectedOption?.subCategoryId?._id,
      name: selectedOption?.subCategoryId?.name,
    });
    setCategory({
      _id: selectedOption?.categoryId._id,
      name: selectedOption?.categoryId.name,
    });
  };
  const getAllUsers = async (search) => {
    try {
      // setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");
      const response = await getRequest(`/api/secure/user/get-all`, token, {
        search: search,
      });

      const mappedOptions = response?.result?.data?.users.map((option) => ({
        value: option._id,
        label: `${option.firstName} ${option.lastName} (${option.email})`,
      }));
      setUsers(mappedOptions);
    } catch (error) {
      console.log("Get All Users Error", error);
    } finally {
      // setLoading(false);
    }
  };
  const userChange = (selectedOption) => {
    setUser(selectedOption);
  };
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept:
      product && product.name.includes("Videos")
        ? {
            "video/mp4": [".mp4", ".MP4"],
          }
        : {
            "audio/mp3": [".mp3"],
          },
    onDrop: (acceptedFiles) => {
      setFeaturedFile(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });
  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "deactive", label: "Deactive" },
    { value: "rejected", label: "Rejected" },
    { value: "pending", label: "Pending" },
  ];
  const statusChange = (selectedOption) => {
    setStatus(selectedOption);
  };
  const editPostHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("title", title);
      if (
        product &&
        (product.name.includes("Stories") || product.name.includes("Blogs") || product.name.includes("Image"))
      ) {
        if (description) {
          formData.append("article", description);

          formData.append("hashtagArray", JSON.stringify(hashtags));
        } else {
          toast.error("Article is Required for Post", {
            position: "top-center",
            theme: "colored",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          return false;
        }
      }

      if (featuredImage) {
        formData.append("featuredImage", featuredImage);
      }
      formData.append("subCategoryId", product && subCategory._id);
      formData.append("categoryId", category && category._id);
      formData.append("productId", product && product._id);
      console.log("challenge", challenge);
      formData.append("challengeId", challenge && challenge._id);
      formData.append(
        "status",
        typeof status === "object" ? status.value : status
      );
      console.log(
        status &&
          (typeof status === "object"
            ? status.value === "deactive" || status.value === "rejected"
            : status === "deactive" || status === "rejected")
      );
      if (
        status &&
        (typeof status === "object"
          ? status.value === "deactive" || status.value === "rejected"
          : status === "deactive" || status === "rejected")
      ) {
        formData.append("reason", reason);
      }
      formData.append("userId", typeof user === "object" ? user.value : user);
      if (
        product &&
        (product.name.includes("Audio Station") ||
          product.name.includes("Videos"))
      ) {
        if (!featuredFile.some((file) => file.hasOwnProperty("data_url"))) {
          if (featuredFile && featuredFile?.length > 0) {
            featuredFile.map((file) => {
              formData.append("featuredFile", file);
            });
          } else {
            toast.error("Please select the file for a post.", {
              position: "top-center",
              theme: "colored",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
            return false;
          }
        }
      }
      console.log("formData", Object.fromEntries(formData));
      const token = getItemFromLocalStorage("TOKEN");
      const response = await putRequest(
        `/api/secure/post/update-post/${postSlug}`,
        token,
        formData
      );

      if (
        response?.result?.status === 200 ||
        response?.result?.status === 201
      ) {
        const { post } = response?.result?.data;

        if (challenge) {
          const { result, error } = await putRequest(
            "/api/secure/challenge/join-challenge",
            token,
            {
              challengeId: challenge?._id,
              postId: post?._id,
            }
          );
          if (result?.status === 200 || result?.status === 201) {
            toast.success("Challenge Joined", {
              position: "top-center",
              theme: "colored",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
          }
        }
        toast.success("Post Updated", {
          position: "top-center",
          theme: "colored",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        navigate(-1);
      }
    } catch (error) {
      console.log("Update Post APi error", error.message);
    } finally {
      setLoading(false);
    }
  };
  const getPostBySlug = async (postSlug) => {
    try {
      setLoading(true);
      const token = getItemFromLocalStorage("TOKEN");

      const response = await getRequest(`/api/secure/post/post`, token, {
        postSlug,
      });

      if (
        response?.result?.status === 200 ||
        response?.result?.status === 201
      ) {
        const {
          featuredFile,
          status,
          title,
          subCategoryId,
          article,
          userId,
          featuredImage,
          categoryId,
          productId,
          challengeId,
          reason,
        } = response?.result?.data?.post;
        setStatus(status);
        setTitle(title);
        if (challengeId) {
          setLinkToChallenge(true);
          setChallenge({
            _id: challengeId?._id,
            name: challengeId?.name,
          });
        }

        // setSubCategory({
        //   _id: subCategoryId?._id,
        //   name: subCategoryId?.name,
        //   parentCategory: {
        //     name: categoryId?.name,
        //   },
        //   masterProduct: {
        //     name: productId?.name,
        //   },
        // });
        // getAllSubCategories(subCategoryId?.name);
        setReason(reason);
        setSubCategory({ _id: subCategoryId?._id, name: subCategoryId?.name });
        setCategory({ _id: categoryId._id, name: categoryId.name });
        setProduct({
          _id: productId._id,
          name: productId.name,
        });
        setUser(userId._id);
        getAllUsers(userId.username);

        if (article) {
          setShortStories(true);
          setDescription(article);
        }
        if (
          featuredImage !== undefined ||
          featuredImage !== "" ||
          featuredImage !== null
        ) {
          setImages([
            {
              data_url: uploadURL + featuredImage,
            },
          ]);
        }
        if (
          featuredFile !== undefined ||
          featuredFile !== "" ||
          featuredFile !== null
        ) {
          setFeaturedFile([{ data_url: uploadURL + featuredFile }]);
        }
      }
    } catch (error) {
      console.log("Get Sub Slider APi error", error.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getPostBySlug(postSlug);
  }, [postSlug, location]);

  const extractMentionsAndHashtags = useCallback((content) => {
    const mentions = [...content.matchAll(/@(\w+)/g)].map((m) => m[1]);
    const hashtags = [...content.matchAll(/#(\w+)/g)].map((h) => h[1]);
    return {
      mentions: [...new Set(mentions)],
      hashtags: [...new Set(hashtags)],
    };
  }, []);
  const handleContentChange = (htmlContent) => {
    setDescription(htmlContent);
    const { mentions, hashtags } = extractMentionsAndHashtags(htmlContent);
    setMentions(mentions);
    setHashtags(hashtags);
  };
  return (
    <Layout>
      <section id="add_user_form_section" className="py-5 upload-video">
        <Container>
          {loading ? (
            <Loader loading={loading} />
          ) : (
            <Form
              className="form_add_user"
              encType="multipart/form-data"
              method="post"
              onSubmit={editPostHandler}
            >
              <Row className="pt-3">
                <Col lg={12} className="mb-5">
                  <ImageUploading
                    value={images}
                    onChange={onChangeImage}
                    dataURLKey="data_url"
                    maxNumber={1}
                    maxFileSize={5000000}
                    acceptType={["jpeg", "jpg", "gif", "png"]}
                    resolutionType="absolute"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageUpdate,
                      isDragging,
                      dragProps,
                      errors,
                    }) => (
                      <Row className="align-items-center mt-5">
                        <Col lg={2} md={3} xs={12}>
                          {imageList.length > 0 ? (
                            imageList.map((image, index) => (
                              <button
                                type="button"
                                className="border-0 bg-transparent"
                                onClick={() => onImageUpdate(index)}
                              >
                                <img
                                  src={image["data_url"]}
                                  className="img-fluid"
                                  alt=""
                                />
                              </button>
                            ))
                          ) : (
                            <button
                              type="button"
                              className="border-0 bg-transparent "
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              <img
                                src={PublicImages.cam}
                                className="img-fluid"
                                alt=""
                              />
                            </button>
                          )}
                        </Col>
                        <Col lg={10} md={9} xs={12}>
                          <h3>Upload Image Post Thumbnail</h3>

                          <div className="dot">
                            {imageList.length > 0 ? (
                              imageList.map((image, index) => (
                                <button
                                  type="button"
                                  className="border-0 bg-transparent"
                                  onClick={() => onImageUpdate(index)}
                                >
                                  <div className="d-flex justify-content-start text-start">
                                    <div>
                                      <h5>
                                        Drop an image file here or click to
                                        upload
                                      </h5>
                                      <p>
                                        .jpeg or .png up to 5MB in size.
                                        1000x1000 high-resolution recommended.
                                      </p>
                                    </div>
                                  </div>
                                </button>
                              ))
                            ) : (
                              <button
                                type="button"
                                className="border-0 bg-transparent "
                                onClick={onImageUpload}
                                {...dragProps}
                              >
                                <div className="d-flex justify-content-start text-start">
                                  <div>
                                    <h5>
                                      Drop an image file here or click to upload
                                    </h5>
                                    <p>
                                      .jpeg or .png up to 5MB in size. 1000x1000
                                      high-resolution recommended.
                                    </p>
                                  </div>
                                </div>
                              </button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    )}
                  </ImageUploading>
                </Col>
                <Col lg={4} md={4} xs={12}>
                  <Form.Label>
                    Title <span>*</span>
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      placeholder={`Title`}
                      className="show "
                      required
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </InputGroup>
                </Col>
                <Col xl={4} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Status</Form.Label>
                    <ReactSelect
                      placeholder="Select Status"
                      onChange={statusChange}
                      options={statusOptions}
                      required
                      value={
                        typeof status === "object"
                          ? status
                          : status
                          ? {
                              value: status,
                              label: getLabelByValue(statusOptions, status),
                            }
                          : { value: "active", label: "Active" }
                      }
                    />
                  </Form.Group>
                </Col>
                <Col xl={4} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>User</Form.Label>
                    <ReactSelect
                      placeholder="Select User"
                      onChange={userChange}
                      options={users}
                      value={
                        typeof user === "object"
                          ? user
                          : user
                          ? {
                              value: user,
                              label: getLabelByValue(users, user),
                            }
                          : { value: "active", label: "Active" }
                      }
                      isClearable
                      required
                      onInputChange={(query) => {
                        getAllUsers(query);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col lg={4} md={4} xs={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Product</Form.Label>
                    <AsyncPaginate
                      value={product ? product : ""}
                      loadOptions={fetchProducts}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option._id}
                      onChange={handleProductChange}
                      placeholder="Select Product"
                      isClearable
                      required
                      loadingMessage={() => "Loading..."}
                      noOptionsMessage={() => "No Product Found"}
                      additional={{
                        page: 1,
                      }}
                      isDisabled={true}
                    />
                  </Form.Group>
                </Col>
                {product && (
                  <Col lg={4} md={4} xs={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Category</Form.Label>
                      <AsyncPaginate
                        value={category ? category : ""}
                        loadOptions={fetchCategories}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option._id}
                        onChange={handleCategoryChange}
                        placeholder="Select Category"
                        isClearable
                        required
                        loadingMessage={() => "Loading..."}
                        noOptionsMessage={() => "No Category Found"}
                        additional={{
                          page: 1,
                        }}
                        isDisabled={linkToChallenge}
                      />
                    </Form.Group>
                  </Col>
                )}
                {category && (
                  <Col lg={4} md={4} xs={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Sub Category</Form.Label>
                      <AsyncPaginate
                        value={subCategory ? subCategory : ""}
                        loadOptions={fetchSubCategories}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option._id}
                        onChange={handleSubCategoryChange}
                        placeholder="Select Sub Category"
                        isClearable
                        required
                        loadingMessage={() => "Loading..."}
                        noOptionsMessage={() => "No Sub Category Found"}
                        additional={{
                          page: 1,
                        }}
                        isDisabled={linkToChallenge}
                      />
                    </Form.Group>
                  </Col>
                )}
                <Col lg={12}>
                  <Form.Group className="mb-3">
                    <Form.Check // prettier-ignore
                      type="switch"
                      id="custom-switch"
                      label="Link to Challenge"
                      checked={linkToChallenge}
                      onChange={() => {
                        setLinkToChallenge((prevState) => !prevState);
                      }}
                    />
                  </Form.Group>
                  {linkToChallenge &&
                    (product && category && subCategory ? (
                      <Form.Group className="mb-3">
                        <Form.Label>Challenge</Form.Label>
                        <AsyncPaginate
                          value={challenge ? challenge : ""}
                          loadOptions={fetchChallenges}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option._id}
                          onChange={hangleChallengeChange}
                          placeholder="Select Challenge"
                          isClearable
                          required
                          loadingMessage={() => "Loading..."}
                          noOptionsMessage={() => "No Challenge Found"}
                          additional={{
                            page: 1,
                          }}
                        />
                      </Form.Group>
                    ) : (
                      <p>
                        Please select the Product, Category and Sub Category
                        first.
                      </p>
                    ))}
                </Col>
                {status &&
                  (typeof status === "object"
                    ? status.value === "deactive" || status.value === "rejected"
                    : status === "deactive" || status === "rejected") && (
                    <Col lg={12} className="">
                      <Form.Group className="mb-3">
                        <Form.Label>Reason</Form.Label>
                        <Form.Control
                          type="text"
                          as="textarea"
                          rows={3}
                          placeholder={`Reason for deactivating the post.`}
                          className="show h-auto"
                          required
                          value={reason}
                          onChange={(e) => setReason(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  )}
                {product &&
                  (product.name.includes("Blogs") ||
                    product.name.includes("Image") ||
                    product.name.includes("Stories")) && (
                    <>
                      <Col lg={12} className="">
                        <Form.Group className="mb-3">
                          <Form.Label>Article </Form.Label>
                          <EditorWithMentionsAndHashtags
                            initialContent={description}
                            onContentChange={handleContentChange}
                          />
                        </Form.Group>
                      </Col>
                    </>
                  )}
                {product &&
                  (product.name.includes("Audio Station") ||
                    product.name.includes("Videos")) && (
                    <>
                      <h4 className="mt-5 p-2">
                        Post File <span>*</span>
                      </h4>

                      {featuredFile.length == 0 && (
                        <div className="mt-1 dot">
                          <div {...getRootProps({ className: "dropzone" })}>
                            <div className="d-flex">
                              <div className="p-2">
                                {product.name.includes("Audio Station") ? (
                                  <img
                                    src={PublicImages.backup}
                                    className="img-fluid"
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    src={PublicImages.slidshow}
                                    className="img-fluid"
                                    alt=""
                                  />
                                )}
                                <input {...getInputProps()} />
                              </div>
                              <div className="p-2">
                                <h5>Drop a file here or click to upload</h5>
                                {product.name.includes("Audio Station") ? (
                                  <p>.mp3, upto 5MB in size</p>
                                ) : (
                                  <p>.mp4, upto 50MB in size</p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="d-flex justify-content-center w-100">
                        {featuredFile?.length > 0 &&
                          featuredFile.map((file, key) => {
                            console.log("file", file);
                            if (file.hasOwnProperty("data_url")) {
                              if (product.name.includes("Videos")) {
                                return (
                                  <div
                                    className="d-flex w-100 position-relative"
                                    key={key}
                                  >
                                    <Ratio aspectRatio="16x9" key={key}>
                                      <video controls>
                                        <source src={file.data_url} />
                                      </video>
                                    </Ratio>
                                    <Button
                                      onClick={() => {
                                        setFeaturedFile([]);
                                      }}
                                      className="clearVideoFile"
                                    >
                                      <FontAwesomeIcon icon={faTimes} />
                                    </Button>
                                  </div>
                                );
                              } else if (
                                product.name.includes("Audio Station")
                              ) {
                                return (
                                  <div className="d-flex gap-4 w-100" key={key}>
                                    <audio className="w-100" controls key={key}>
                                      <source src={file.data_url} />
                                    </audio>
                                    <Button
                                      onClick={() => {
                                        setFeaturedFile([]);
                                      }}
                                      className="clearAudioFile"
                                    >
                                      <FontAwesomeIcon icon={faTimes} />
                                    </Button>
                                  </div>
                                );
                              }
                            } else {
                              if (product.name.includes("Videos")) {
                                return (
                                  <div
                                    className="d-flex w-100 position-relative"
                                    key={key}
                                  >
                                    <Ratio aspectRatio="16x9" key={key}>
                                      <video controls>
                                        <source
                                          src={file.preview}
                                          type={file.type}
                                        />
                                      </video>
                                    </Ratio>
                                    <Button
                                      onClick={() => {
                                        setFeaturedFile([]);
                                      }}
                                      className="clearVideoFile"
                                    >
                                      <FontAwesomeIcon icon={faTimes} />
                                    </Button>
                                  </div>
                                );
                              } else if (
                                file.type.startsWith("audio/") &&
                                product.name.includes("Audio Station")
                              ) {
                                return (
                                  <div className="d-flex gap-4 w-100" key={key}>
                                    <audio className="w-100" controls key={key}>
                                      <source
                                        src={file.preview}
                                        type={file.type} // Hardcoded audio type
                                      />
                                    </audio>
                                    <Button
                                      onClick={() => {
                                        setFeaturedFile([]);
                                      }}
                                      className="clearAudioFile"
                                    >
                                      <FontAwesomeIcon icon={faTimes} />
                                    </Button>
                                  </div>
                                );
                              }
                            }
                          })}
                      </div>
                    </>
                  )}
                <div className="d-flex  pt-5">
                  <InputGroup className="justify-content-center">
                    <Button type="submit" className="btn-default">
                      Update
                    </Button>
                  </InputGroup>
                </div>
              </Row>
            </Form>
          )}
        </Container>
      </section>
    </Layout>
  );
};

export default EditPost;
